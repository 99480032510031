import { createClient } from "contentful";
import config from "../config";

const { spaceID, contentAPI } = config;

export const getClient = async () => {
  const client = await createClient({
    space: spaceID,
    accessToken: contentAPI,
  });
  return client;
};

export const getBios = async () => {
  const client = await getClient();
  const data = await client.getEntries({
    content_type: "teamMemberBio",
    include: "2",
    order: "fields.id",
  });
  const massagedData = data.items.map((item, index) => {
    const { biography, email, jobTitle, name, photo } = item.fields;
    return {
      id: index,
      name,
      jobTitle,
      email,
      biography,
      imgsrc: photo.fields.file.url,
    };
  });
  return massagedData;
};
