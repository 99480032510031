import { BLOCKY_NAV, NAVIGATE_TO_PORTFOLIOS, STATE_HISTORY } from '../constants';

export const initialState = {
	headerWrapperStyle: { height: '0', position: 'relative' },
	navigateToPortfolio: false
};

const mainReducer = (state = initialState, action) => {
	switch (action.type) {
		case BLOCKY_NAV: // For Nav 
			return {
				...state,
				...action.payload,
				navigateToPortfolio: false
			};
		case NAVIGATE_TO_PORTFOLIOS: // For homepage to portfolios
			return {
				...state,
				...action.payload,
				navigateToPortfolio: true
			}
		case STATE_HISTORY:
			return {
				...state,
				...action.payload
			}
		default:
			return state;
	}
	
};

export default mainReducer;