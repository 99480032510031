import React, { Component } from 'react';
import './styles.scss';

class SideMenu extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { sideMenuClassName, nextPortfolio, title, handleClick, sideMenuDirection} = this.props;

		return (
			<div className={`side-menu side-menu-${sideMenuClassName}`}>
				<div className="side-menu-wrapper">
					{/* <div className="side-line-vertical"></div> */}
					<div 
						className={`side-menu-text portfolio-id-${nextPortfolio}`}
						title={title}
						onClick={handleClick}>
						{sideMenuDirection}
					</div>
				</div>
			</div>
		);
	}
}

export default SideMenu;
