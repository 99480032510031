import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAllCaseStudies } from "../../../actions/case-studies";
import { getAllCaseStudies as getAllCaseStudiesSelector } from "../../../selectors/case-studies";
import { getAllCaseStudes as getAllCaseStudiesService } from "../../../services/getContent";
import PortfolioIndividual from "../Portfolio-Individual";
import portfolios from "../list-of-portfolios";

export const Portfolio = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const caseStudies = useSelector(getAllCaseStudiesSelector);
  useEffect(() => {
    if (!caseStudies.length) {
      const get = async () => {
        const data = await getAllCaseStudiesService();
        dispatch(getAllCaseStudies(data));
      };
      get();
    }
  }, []);

  const currentPortfolioData = {
    caseName: location.pathname.split("/")[2],
    prevPortfolio: {},
    nextPortfolio: {},
  };

  // Gets current portfolio to show and also populates prev and next portfolios
  const currentPortfolio = caseStudies.find((study, index) => {
    if (
      study.url.toLowerCase() === currentPortfolioData.caseName.toLowerCase()
    ) {
      currentPortfolioData.prevPortfolio =
        index - 1 < 0
          ? caseStudies[caseStudies.length - 1]
          : caseStudies[index - 1];
      currentPortfolioData.nextPortfolio =
        index + 1 >= caseStudies.length
          ? caseStudies[0]
          : caseStudies[index + 1];
      return true;
    }
    return false;
  });

  const { prevPortfolio, nextPortfolio } = currentPortfolioData;
  if (!caseStudies.length) {
    return null;
  }

  return (
    <PortfolioIndividual
      individualType={currentPortfolio}
      prevPortfolio={prevPortfolio}
      nextPortfolio={nextPortfolio}
    />
  );
};

export const Osk = () => {
  return (
    <React.Fragment>
      <PortfolioIndividual
        prevPortfolio={portfolios[7]}
        individualType={portfolios[0]}
        nextPortfolio={portfolios[1]}
      />
    </React.Fragment>
  );
};

export const DogQuality = () => {
  return (
    <React.Fragment>
      <PortfolioIndividual
        prevPortfolio={portfolios[0]}
        individualType={portfolios[1]}
        nextPortfolio={portfolios[2]}
      />
    </React.Fragment>
  );
};

export const SportAbility = () => {
  return (
    <React.Fragment>
      <PortfolioIndividual
        prevPortfolio={portfolios[1]}
        individualType={portfolios[2]}
        nextPortfolio={portfolios[3]}
      />
    </React.Fragment>
  );
};

export const Bcmos = () => {
  return (
    <React.Fragment>
      <PortfolioIndividual
        prevPortfolio={portfolios[2]}
        individualType={portfolios[3]}
        nextPortfolio={portfolios[4]}
      />
    </React.Fragment>
  );
};

export const POL = () => {
  return (
    <React.Fragment>
      <PortfolioIndividual
        prevPortfolio={portfolios[3]}
        individualType={portfolios[4]}
        nextPortfolio={portfolios[5]}
      />
    </React.Fragment>
  );
};

export const VGC = () => {
  return (
    <React.Fragment>
      <PortfolioIndividual
        prevPortfolio={portfolios[4]}
        individualType={portfolios[5]}
        nextPortfolio={portfolios[6]}
      />
    </React.Fragment>
  );
};

export const Nemesis = () => {
  return (
    <React.Fragment>
      <PortfolioIndividual
        prevPortfolio={portfolios[5]}
        individualType={portfolios[6]}
        nextPortfolio={portfolios[7]}
      />
    </React.Fragment>
  );
};

export const Racinne = () => {
  return (
    <React.Fragment>
      <PortfolioIndividual
        prevPortfolio={portfolios[6]}
        individualType={portfolios[7]}
        nextPortfolio={portfolios[0]}
      />
    </React.Fragment>
  );
};
