import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Asset from './components/Asset'
import Paragraph from './components/Paragraph'
import { IframeContainer } from './components'

import {
  OL_LIST,
  LIST_ITEM,
  TEXT,
  PARAGRAPH,
  HYPERLINK,
  ASSET,
  BLOCKQUOTE
} from './constants'

const createOptions = (customOptions = {}) => {
  const options = {
    renderNode: {
      [BLOCKQUOTE]: (blockquote) => {
        const { content } = blockquote
        return content.map((blockquoteLine) => {
          return documentToReactComponents(blockquoteLine, options)
        })
      },
      [HYPERLINK]: (link) => {
        const { uri } = link.data
        if (uri.indexOf('www.youtube') !== -1 || uri.indexOf('vimeo.com') !== -1) {
          return (
            <IframeContainer>
              <iframe title="Unique Title 001" src={uri} frameBorder="0" allowFullScreen />
            </IframeContainer>
          )
        }

        return (
          <a
            href={link.data.uri}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline', display: 'inline-block' }}
            onClick={() => { window.location.href = link.data.uri }}
          >
            {link.content[0].value}
          </a>
        )
      },
      [ASSET]: (asset) => {
        const linkUrl = asset.content[0].value || ''
        const { url } = asset.data.target.fields.file
        const isLinkUrl = linkUrl.indexOf('http') !== -1
          || linkUrl.indexOf('www') !== -1
          || linkUrl.indexOf('.com') !== -1
          || linkUrl.indexOf('.ca') !== -1

        if (!isLinkUrl) {
          return <Asset>{url}</Asset>
        }
        
        return (
          <a
            href={isLinkUrl ? linkUrl : '#'}
            target={isLinkUrl ? '_blank' : ''}
            rel="noopener noreferrer"
          >
            <Asset>{url}</Asset>
          </a>
        )
      },
      [OL_LIST]: (list) => {
        const { content } = list
          return (
            <ol>
              {
                content.map((listItem) => {
                  return documentToReactComponents(listItem, options)
                })
              }
            </ol>
          )
      },
      [TEXT]: (text) => {
        if (text.content.length > 1) {
          return text.content.map((contentItem) => {
            return documentToReactComponents(contentItem, options)
          })
        }

        const { value, marks } = text.content[0]
        
        if (value === '') {
          return <br />
        }

        const styleProps = {}
        marks.forEach((mark) => {
          styleProps[mark.type] = true
        })

        return (
          <Paragraph styleProps={styleProps}>{value}</Paragraph>
        )
      },
      [PARAGRAPH]: (paragraph) => {
        if (paragraph.content.length > 1) {
          const children = paragraph.content.map((contentItem) => {
            const { value, nodeType } = contentItem
            
            if (nodeType === 'text') {
              return <span>{value}</span>
              // return <Paragraph style={{ display: 'inline-block' }}>{value}</Paragraph>
            }
            // if (nodeType === 'hyperlink') {
            //   return documentToReactComponents(contentItem, options)
            // }
            return documentToReactComponents(contentItem, options)
          })
          
        return (
          <Paragraph>
            {children.map((child) => {
              return child
            })}
          </Paragraph>
        )
        }

        const { value, marks } = paragraph.content[0]
        
        if (value === '') {
          return <br />
        }

        const styleProps = {}
        marks.forEach((mark) => {
          styleProps[mark.type] = true
        })

        return (
          <Paragraph styleProps={styleProps}>{value}</Paragraph>
        )
      },
      [LIST_ITEM]: (listItem) => {
        const listItemContent = listItem.content[0]
        return (
          <li>
            {
              documentToReactComponents(listItemContent, options)
            }
          </li>
        )
      }
    }
  }  

  return options
}

export default createOptions
