// eslint-disable-next-line no-unused-vars
import React from 'react'
import pt from 'prop-types'
import { useHistory } from 'react-router-dom'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import createOptions from './createOptions'

const BlogContent = ({ contentItem }) => {
  const history = useHistory()
  const customOptions = {
    history
  }
  const createdOptions = createOptions(customOptions)
  const formattedComponents = documentToReactComponents(contentItem, createdOptions)
  return (
    <>
      {
        formattedComponents
      }
    </>
  )

}

BlogContent.propTypes = {
  contentItem: pt.object.isRequired
}

export default BlogContent
