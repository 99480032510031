import styled from 'styled-components'
import { background } from 'styled-system'
import { Flex, Box } from '@rebass/grid'

export const Container = styled(Box)`
  ${background}
  width: 100vw;
`

export const SubscribeContainer = styled(Flex)`
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
`

export const Input = styled.input`
  color: ${({ color }) => color} !important;
  border-bottom: ${({ color }) => `1px solid ${color}`} !important;
  font-family: Sailec-Regular;
  padding-top: 40px;
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus, 
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 40px ${({ backgroundColor }) => backgroundColor} inset !important;
  };
`
