import React, { Component } from "react";
import SideNav from "../common/SideNav";
import { connect } from "react-redux";
import { createBlockyNav } from "../../actions";
import { getBios } from "../../services/getBios";

import "./styles/index.scss";
import { FooterConsultation } from "../common/footer-consultation";

class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      members: [],
      nameToShow: "",
      detailToShow: "",
      cardDetails: {},
    };

    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
    this.listenForEscape = this.listenForEscape.bind(this);
  }

  async componentDidMount() {
    const headerWrapperStyle = {
      height: "60px",
    };

    const classNameNavBar = "header-container header-container-solid";
    const classNameBurgerIcon = "header-menu-icon header-menu-icon-black";
    const shouldChangeNav = false;

    this.props.createBlockyNav({
      shouldChangeNav,
      headerWrapperStyle,
      classNameNavBar,
      classNameBurgerIcon,
    });
    const allBios = await getBios();

    this.setState({
      ...this.state,
      members: allBios,
    });
  }

  // Show Card details when hovering over each card
  handleMouseOver(e) {
    // Temp fix for click issue on mobile
    if (window.outerWidth > 768) {
      // Determine if user hovered on image or not
      const nameToShow = e.target.classList.contains("team-container")
        ? ""
        : e.target.closest(".member-container").id;

      this.setState({
        nameToShow,
      });
    }
  }

  handleMouseLeave(e) {
    this.setState({
      nameToShow: "",
    });
  }

  handleClick(e) {
    // Determine if user clicked on name/title or not
    const { members } = this.state;
    const detailToShow = e.currentTarget.id;
    let cardDetails = {};

    // Loop through and match the id with the member's id
    // Then setState with new info for component to render card

    for (let i = 0; i < this.state.members.length; i++) {
      if (members[i].id.toString() === detailToShow) {
        cardDetails = { ...members[i] };

        this.listenForEscape(); // Escape key will also trigger handleOverlayClick

        this.setState({
          nameToShow: "",
          detailToShow,
          cardDetails,
        });
        return;
      }
    }
  }

  // Do not show details when clicking overlay
  handleOverlayClick() {
    document.getElementsByClassName("employee-card")[0].classList.add("hide"); // Add hide transition before setting state

    setTimeout(() => {
      this.setState({
        detailToShow: "",
      });
    }, 250);
  }

  // When clicking escape, remove overlay
  listenForEscape() {
    const emulateHandleOverlayClick = (e) => {
      if (e.keyCode === 27) {
        this.handleOverlayClick();
        document.removeEventListener("keyup", emulateHandleOverlayClick);
      }
    };

    document.addEventListener("keyup", emulateHandleOverlayClick);
  }

  // Refactor this...setting nav back to normal
  componentWillUnmount() {
    const headerWrapperStyle = {
      height: "0",
    };

    const classNameNavBar = "header-container";
    const classNameBurgerIcon = "header-menu-icon";
    const shouldChangeNav = true;

    this.props.createBlockyNav({
      shouldChangeNav,
      headerWrapperStyle,
      classNameNavBar,
      classNameBurgerIcon,
    });
  }

  render() {
    const { members, nameToShow, detailToShow, cardDetails } = this.state;
    if (!members.length) return null;
    const membersList = members.map((member, index) => {
      let componentToShow = {};

      // If Detail to show is true
      // Show member Detail for only memeber with corresponding index
      if (nameToShow) {
        // This is on Hover Element
        const memberCardWithNameAndTitle = (
          <div
            className="member-details-container"
            id={member.id}
            onClick={this.handleClick}
            onMouseLeave={this.handleMouseLeave}
            data-aos="fade"
            data-aos-duration="200"
          >
            <div className="member-details">
              <h3 className="member-name">{member.name}</h3>
              <p className="member-title">{member.jobTitle}</p>
            </div>
          </div>
        );

        // If card to show number matches the index number of the current card that is being mapped/looped...
        // Render card with name and title. Else, render the image
        Number(nameToShow) === index
          ? (componentToShow = (
              <div
                className="member-container"
                id={index}
                key={`${member.name.replace(
                  / +/g,
                  ""
                )}-${member.jobTitle.replace(/ +/g, "")}`}
                onMouseLeave={this.handleMouseLeave}
              >
                <div className="member-img">
                  <img src={member.imgsrc} alt="" />
                </div>
                {memberCardWithNameAndTitle}
              </div>
            ))
          : (componentToShow = ( // This is the component without the onHover Element
              <div
                className="member-container"
                id={index}
                key={`${member.name.replace(
                  / +/g,
                  ""
                )}-${member.jobTitle.replace(/ +/g, "")}`}
                onMouseOver={this.handleMouseOver}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleClick}
              >
                <div className="member-img">
                  <img src={member.imgsrc} alt={member.imgsrc} />
                </div>
              </div>
            ));

        return componentToShow;
      } else {
        return (
          <div
            className="member-container"
            id={index}
            key={`${member.name.replace(/ +/g, "")}-${member.jobTitle.replace(
              / +/g,
              ""
            )}`}
            onMouseOver={this.handleMouseOver}
            onMouseLeave={this.handleMouseLeave}
            onClick={this.handleClick}
          >
            <div className="member-img">
              <img src={member.imgsrc} alt={member.imgsrc} />
            </div>
          </div>
        );
      }
    });

    return (
      <div>
        {// Show Details in center of page if card is clicked
        detailToShow.length > 0 ? (
          <div
            className="employee-card"
            data-aos="fade"
            data-aos-duration="200"
          >
            <div
              className="employee-details-overlay"
              onClick={this.handleOverlayClick}
            />
            <div className="employee-details-container">
              <div className="employee-details">
                <div
                  className="employee-details-close"
                  onClick={this.handleOverlayClick}
                >
                  <span>✕</span>
                </div>
                <div
                  className="employee-details-image"
                  data-image={`image-${cardDetails.id}`}
                >
                  <img src={cardDetails.imgsrc} alt={cardDetails.imgsrc} />
                </div>
                <div className="employee-details-text-container">
                  <div className="employee-details-text">
                    <h1>{cardDetails.name}</h1>
                    <p className="employee-details-text-title">
                      {cardDetails.jobTitle}
                    </p>
                    <p>{cardDetails.biography}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="our-team-container">
          <SideNav
            title="OUR TEAM"
            containerStyle={{ top: "0" }}
            initialHeight={0}
            isTop={true}
            parentClassName="our-team-container"
          />
          <div
            className="team-container"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            {membersList}
          </div>
        </div>
        <FooterConsultation />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createBlockyNav: (payload) => dispatch(createBlockyNav(payload)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Team);
