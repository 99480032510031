import { BLOCKY_NAV, NAVIGATE_TO_PORTFOLIOS, STATE_HISTORY } from '../constants';

export const createBlockyNav = payload => {
	return { 
		type: BLOCKY_NAV, 
		payload 
	};
};

export const navigateToPortfolios = payload => {
	
	return {
		type: NAVIGATE_TO_PORTFOLIOS,
		payload
	}
}

export const updateStateForPortfolios = payload => {
	return {
		type: STATE_HISTORY,
		payload
	}
}
