import React, { Component } from 'react';
import './styles.scss';

class SideNav extends Component {
	constructor(props) {
		super(props);

		const threshold = 
			props.isTop ? 0 : 500;

		this.state = {
			height: 0,
			threshold
		}

		this.growLine = this.growLine.bind(this);	
	}

	growLine() {
		
		setTimeout(() => {
			const { parentClassName } = this.props;

			if (parentClassName !== undefined) {

				const offset = document.getElementsByClassName(parentClassName)[0].getBoundingClientRect().top;
				
				if (offset <= this.state.threshold) {
					this.setState({
						height: '125px'
					});

					window.removeEventListener('scroll', this.growLine);
				}
			}
		}, 10);
	}

	componentDidMount() {
		this.state.threshold > 0 
			? window.addEventListener('scroll', this.growLine)
			: setTimeout(() => this.setState({ height: '125px' }), 500);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.growLine);
	}

	render() {
		const { height } = this.state;
		const { title, containerStyle, titleStyle, } = this.props;

		return (
			<div className="side-nav-container" style={ containerStyle }>
				<div className="line-vertical" style={{ height }}></div>
				<div className="side-nav-wrapper">
					<div className="side-nav" style={ titleStyle }>{title}</div>
				</div>
			</div>
		);
	}
}

export default SideNav;
