import React from 'react'
import pt from 'prop-types'

import { Flex, Box } from '@rebass/grid'

import LineVertical from '../../common/LineVertical';

const Query = ({
  query
}) => {
  return (
    <Box mt={[4]} mb={['100px']}>
      <Box style={{ height: '100px' }}>
        <LineVertical
          customStyle={{
            height: '100px'
          }}
        />
      </Box>
      <Flex justifyContent="center">
        <h1
          style={{
            color: '#FF5A1E',
            marginBottom: 0
          }}
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {
            `Search Result: ${query}`
          }
        </h1>
      </Flex>
    </Box>
  )
}

Query.propTypes = {
  query: pt.string.isRequired
}

export default Query
