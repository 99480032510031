import React from 'react'

import { Flex } from '@rebass/grid'

const Asset = ({ children }) => {
  return (
    <Flex width={'100%'} justifyContent="center">
      <img
        style={{
          width: 'calc(100% + 50px)',
          height: '100%'
        }}
        src={children}
        alt=""
      />
    </Flex>
  )
}

export default Asset
