import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'

export const OL_LIST = [BLOCKS.OL_LIST]
export const UL_LIST = [BLOCKS.UL_LIST]
export const LIST_ITEM = [BLOCKS.LIST_ITEM]
export const PARAGRAPH = [BLOCKS.PARAGRAPH]
export const BOLD = [MARKS.BOLD]
export const ITALIC = [MARKS.ITALIC]
export const UNDERLINE = [MARKS.UNDERLINE]
export const HYPERLINK = [INLINES.HYPERLINK]
export const ASSET = [INLINES.ASSET_HYPERLINK]
export const ENTRY = [INLINES.ENTRY_HYPERLINK]
export const HR = [BLOCKS.HR]
export const BLOCKQUOTE = 'blockquote'
export const TEXT = 'text'
export const HEADING_ONE = [BLOCKS.HEADING_1]
export const HEADING_TWO = [BLOCKS.HEADING_2]
export const HEADING_THREE = [BLOCKS.HEADING_3]
export const HEADING_FOUR = [BLOCKS.HEADING_4]
export const HEADING_FIVE = [BLOCKS.HEADING_5]
export const HEADING_SIX = [BLOCKS.HEADING_6]
