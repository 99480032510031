import React, { useState } from "react";
import pt from "prop-types";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import Grid from "../Grid";

import config from "../../../config";
import { Container, SubscribeContainer, Input } from "./components";

const { MAILCHIMP_SNIPPET } = config;

const Subscribe = ({ color, backgroundColor, padding }) => {
  const [emailValue, setEmailValue] = useState("");

  const toggleFocus = (e) => {
    e.target.nextSibling.className.indexOf("input-focused") > -1 &&
    !e.target.value.length
      ? (e.target.nextSibling.className = "")
      : (e.target.nextSibling.className = "input-focused");
  };
  return (
    <Container background={backgroundColor}>
      <Grid>
        <SubscribeContainer
          className="subscribe"
          justifyContent={["center", "center", "center", "space-between"]}
          alignItems="center"
          py={padding.py}
        >
          <h2 className="text" style={{ color }}>
            Subscribe for more <br />
            storytelling content
          </h2>
          <MailchimpSubscribe
            url={MAILCHIMP_SNIPPET}
            render={({ subscribe, status, message }) => (
              <form
                // eslint-disable-next-line no-script-url
                action="javascript:void(0);"
                method="get"
                // style={{ lineHeight: 10 }}
              >
                <div className="wf-form-section wf-form-section-inline">
                  <Input
                    color={color}
                    backgroundColor={backgroundColor}
                    name="email"
                    type="email"
                    onClick={toggleFocus}
                    onBlur={toggleFocus}
                    onChange={(e) => {
                      setEmailValue(e.target.value);
                    }}
                  />
                  <label
                    htmlFor="email"
                    style={{
                      color,
                      letterSpacing: "1px",
                    }}
                  >
                    EMAIL
                  </label>
                </div>
                <button
                  className="wf-contact-submit"
                  onClick={() => {
                    subscribe({
                      NAME: "Newsletter subscription",
                      EMAIL: emailValue,
                      PHONE: "123-123-1234",
                      WEBSITE: "Newsletter subscription",
                      MESSAGE: "Newsletter subscription",
                    });
                  }}
                >
                  Submit
                </button>
                {status === "sending" && <div>sending...</div>}
                {status === "error" && <div>sending...</div>}
                {status === "success" && <div>{message}</div>}
              </form>
            )}
          />
        </SubscribeContainer>
      </Grid>
    </Container>
  );
};

Subscribe.propTypes = {
  color: pt.string,
  backgroundColor: pt.string,
  padding: pt.shape({
    py: pt.array,
    px: pt.array,
  }),
};

Subscribe.defaultProps = {
  color: "#000",
  backgroundColor: "#fff",
  padding: {
    py: [160, 160, 160, 124],
    px: [],
  },
};

export default Subscribe;
