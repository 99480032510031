import styled from "styled-components";
import { fontSize, lineHeight } from "styled-system";

import { Box } from "@rebass/grid";

export const Small = styled(Box)`
  margin: 0;
  font-size: 0.9em;
  line-height: 0.9em;
  ${fontSize}
  ${lineHeight}
`;
