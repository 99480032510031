import { MAILCHIMP_SNIPPET } from "../.config.js";
import { spaceID, contentAPI, previewAPI, content } from "../.contentful.js";

export default {
  spaceID,
  contentAPI,
  previewAPI,
  content,
  MAILCHIMP_SNIPPET,
};
