import React from "react";
import PropTypes from "prop-types";
import { Fade } from "react-reveal";
import "./style.scss";

const PortfolioTemplate = ({
  index,
  portfolioID,
  title,
  category,
  clientName,
  handleClick,
}) => {
  return (
    <Fade duration={1500}>
      <div
        id={portfolioID}
        className={`portfolio-container-${category} portfolio-id portfolio-id-${portfolioID} portfolio-img`}
        title={title}
        data-index-number={index}
        onClick={handleClick}
      >
        <div
          className="portfolio-info"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-offset="-100"
        >
          <h3 className="portfolio-title wf-portfolio-text-clientname">
            {clientName}
          </h3>
          <p className="wf-text-custom wf-portfolio-text-title">{title}</p>
        </div>
      </div>
    </Fade>
  );
};

PortfolioTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  portfolioID: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
};

export default PortfolioTemplate;
