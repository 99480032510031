import classNames from "classnames";
import React, { Component } from "react";
import Button from "../common/Button";
import Circles from "../common/Circles";
import { Footer2022 } from "../common/Footer2022";
import SideNav from "../common/SideNav";
import { FooterConsultation } from "../common/footer-consultation";
import VideoEmbed from "../common/videoEmbed";

import { connect } from "react-redux";
import { createBlockyNav } from "../../actions";

import "../../resources/styles/main.scss";
import "./styles/index.scss";

import Process from "./Process";

// import LOGO_GRANTED_CONSULTING from "../../resources/images/client-icons/logo-grantedconsulting.png";
// import LOGO_KPMG from "../../resources/images/client-icons/logo-kpmg.png";
// import LOGO_TOURISM_VAN from "../../resources/images/client-icons/logo-tourismvancouver.png";
// import LOGO_VBOT from "../../resources/images/client-icons/logo-vbot.png";
// import LOGO_YVR from "../../resources/images/client-icons/logo-yvr.png";
// import LOGO_POL from "../../resources/images/client-icons/logo-pol.png";
// import LOGO_BMOS from "../../resources/images/client-icons/logo-bcmos.jpg";
// import LOGO_PTB from "../../resources/images/client-icons/PTB_HorizontalTagline_BW.png";

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInitialRender: true,
      slideToShow: 0,
      intervalTime: 5000,
      videoStyle: {
        parentStyle: {
          classNames: "modal-demo-video-container",
        },
        // videoSrc: "https://player.vimeo.com/video/531493164",
        iframe: {
          style: {},
          width: "100%",
          height: "600",
          classNames: "modal-demo-video",
        },
      },
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const slideToShow =
      e.target.id.length !== 0 || typeof e.target.id !== "undefined"
        ? Number(e.target.id)
        : "";

    // Cancel on click and start again
    this.cancelLoop();
    this.loopThroughImages();

    this.setState({
      slideToShow,
    });
  }

  // Loop through images automatically for hero banners
  loopThroughImages(intervalTime = this.state.intervalTime) {
    this.imageLooper = setInterval(() => {
      this.setState({
        slideToShow:
          this.state.slideToShow < 2 ? this.state.slideToShow + 1 : 0,
      });
    }, intervalTime);
  }

  // Cancel image looper
  cancelLoop() {
    clearInterval(this.imageLooper);
  }

  componentDidMount(prevProps) {
    // Loop through images automatically
    this.loopThroughImages();

    // Function to execute on scroll
    const setInitialRendervalue = () =>
      this.setState({ isInitialRender: false });

    // Add event listener for scroll on first render
    if (this.state.isInitialRender) {
      window.addEventListener("scroll", () => {
        // Wait 300ms before setting isInitialRender value and then removing the event listener
        setTimeout(() => {
          setInitialRendervalue();
          window.removeEventListener("scroll", setInitialRendervalue);
        }, 300);
      });
    }
  }

  componentWillMount() {
    this.cancelLoop();
  }

  render() {
    const { isInitialRender, showDemo, slideToShow, videoStyle } = this.state;
    const { content } = this.props;
    const {
      aboutSecondFoldVideo,
      aboutBannerCarouselImages,
      aboutPageHeroText,
      aboutPageHeroTextHighlight,
      aboutPageCenterWhiteBoxText,
      aboutUsVideoTopParagraph,
      aboutUsVideoBottomParagraph,
      aboutUsHeartTextHeader,
      aboutUsHeartTextParagraph,
      aboutUsProcessTextHeader,
      aboutUsProcessTextStepOneHeader,
      aboutUsProcessTextStepOneDetails,
      aboutUsProcessTextStepTwoHeader,
      aboutUsProcessTextStepTwoDetails,
      aboutUsProcessTextStepThreeHeader,
      aboutUsProcessTextStepThreeDetails,
      aboutUsProcessTextStepFourHeader,
      aboutUsProcessTextStepFourDetails,
      aboutUsVideoClientsImages,
      aboutUsFourthFoldCtaText,
    } = content;

    if (
      aboutPageHeroText === undefined ||
      aboutPageHeroTextHighlight === undefined ||
      !aboutBannerCarouselImages.length
    ) {
      return null;
    }

    const slideMap = {
      0: `url("https://${aboutBannerCarouselImages[0].fields.file.url.replace('//', '')}")`,
      1: `url("https://${aboutBannerCarouselImages[1].fields.file.url.replace('//', '')}")`,
      2: `url("https://${aboutBannerCarouselImages[2].fields.file.url.replace('//', '')}")`,
    }

    if (this.state.showDemo) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    // Classnames for animations
    const brandLoyalist = classNames({
      "brand-loyalist": true,
      "brand-loyalist-hide": isInitialRender,
    });

    /************************************************************
     * MINI COMPONENTS
     ************************************************************/

    // BANNER
    const bannerText = () => {
      const splits = aboutPageHeroText.split(aboutPageHeroTextHighlight);
      const firstLine = splits[0];
      const thirdLine = splits[1];
      return (
        <div
          className="banner-about-text"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1 style={{ fontSize: "50px" }}>
            {firstLine}
            <span className="wf-theme-accent">
              {aboutPageHeroTextHighlight}
            </span>
            {thirdLine}
          </h1>
        </div>
      );
    };

    const brandLoyalty = (
      <div className={brandLoyalist}>
        <h3 data-aos="fade-up" data-aos-duration="1000">
          {aboutPageCenterWhiteBoxText}
        </h3>
      </div>
    );

    const bannerAboutStyle = {
      background: slideMap[slideToShow]
    }

    const bannerAbout = (
      <div
        className={"banner-about"}
        style={{
          ...bannerAboutStyle,
        }}
      >
        <Circles
          numberOfSlides={3}
          activeNumber={slideToShow}
          onClick={this.handleClick}
        />
        {bannerText()}
      </div>
    );

    // VIDEO REEL
    const videoReelHeader = (
      <div className="video-reel-header">
        <p data-aos="fade-up" data-aos-duration="1000">
          {aboutUsVideoTopParagraph}
        </p>
      </div>
    );

    //     const LOGO_KPMG = '../../../resources/images/client-icons/logo-kpmg.png';
    // const LOGO_TOURISM_VAN = '../../../resources/images/client-icons/logo-tourismvancouver.png';
    // const LOGO_VBOT = '../../../resources/images/client-icons/logo-vbot.png';
    // const LOGO_YVR = '../../../resources/images/client-icons/logo-yvr.png';
    // const LOGO_POL = '../../../resources/images/client-icons/logo-pol-2.jpg';
    // const LOGO_BMOS = '../../../resources/images/client-icons/logo-bcmos.jpg';
    // const LOGO_PTB = '../../../resources/images/client-icons/PTB_HorizontalTagline_BW.png';

    const videoReelClients = (
      <div
        className="video-reel-clients-container wrapper"
        style={{ minHeight: "400px" }}
      >
        <div className="video-reel-clients-text">
          <p
            data-aos="fade-up"
            data-aos-duration="1000"
            style={{ textAlign: "center" }}
          >
            {aboutUsVideoBottomParagraph}
          </p>
        </div>
        <div className="video-reel-clients">
          <img
            src="https://images.ctfassets.net/a826mlmnqk6u/5kqK0LRTspGJfadjgrAua9/1597b21c081291bd3af2a9ff327eef58/video_reel_clients.png"
            alt="video reel clients"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </div>
      </div>
    );

    const videoContainer = (
      <div className="video-reel-container wrapper">
        {brandLoyalty}
        {videoReelHeader}
        <VideoEmbed {...videoStyle} videoSrc={`https://player.vimeo.com/video/${aboutSecondFoldVideo}`} />
      </div>
    );

    // HEART
    const titleStyles = {
      width: "158px",
      marginLeft: "-18px",
      marginTop: "48px",
    };

    const containerStyles = {
      left: "95%",
    };

    const theHeart = (
      <>
        <div className="heart-container wrapper">
          <SideNav
            initialHeight={125}
            title="WE ARE WAKEFIELD"
            containerStyle={containerStyles}
            titleStyle={titleStyles}
            parentClassName="heart-container"
          />
          <div className="heart-content">
            <div className="heart-content-header">
              <h2>{aboutUsHeartTextHeader}</h2>
            </div>
            <div className="heart-content-text">
              <p data-aos="fade-up" data-aos-duration="1000">
                {aboutUsHeartTextParagraph}
              </p>
            </div>
            <div style={{ margin: "24px 20px 20px 20px" }}>
              <Button
                text={aboutUsFourthFoldCtaText}
                isNewWindow
                href="https://wakefield.agency/contact-us"
              />
            </div>
          </div>
        </div>
        {videoReelClients}
      </>
    );

    // DEMO IMAGES
    const demoImages = (
      <div className="demo-images-container">
        <div data-aos="fade-up" data-aos-duration="1000">
          <div className="demo-images" style={{ backgroundImage: `url("https:${aboutUsVideoClientsImages[0].fields.file.url}")` }} />
          <div className="demo-images" style={{ backgroundImage: `url("https:${aboutUsVideoClientsImages[1].fields.file.url}")` }} />
        </div>
      </div>
    );

    /************************************************************
     * COMPONENT TO RETURN
     ************************************************************/

    const containerClassName = showDemo ? "container with-modal" : "container";

    const processTextContent = {
      aboutUsProcessTextHeader,
      aboutUsProcessTextStepOneHeader,
      aboutUsProcessTextStepOneDetails,
      aboutUsProcessTextStepTwoHeader,
      aboutUsProcessTextStepTwoDetails,
      aboutUsProcessTextStepThreeHeader,
      aboutUsProcessTextStepThreeDetails,
      aboutUsProcessTextStepFourHeader,
      aboutUsProcessTextStepFourDetails,
    };

    return (
      <div>
        <div className={containerClassName}>
          {bannerAbout}
          {videoContainer}
          <Process processTextContent={processTextContent} />
          {theHeart}
          {demoImages}
          <FooterConsultation />
          <Footer2022 />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createBlockyNav: (payload) => dispatch(createBlockyNav(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    content: {
      aboutBannerCarouselImages: state.content.aboutBannerCarouselImages,
      aboutPageHeroText: state.content.aboutPageHeroText,
      aboutPageHeroTextHighlight: state.content.aboutPageHeroTextHighlight,
      aboutPageCenterWhiteBoxText: state.content.aboutPageCenterWhiteBoxText,
      aboutSecondFoldVideo: state.content.aboutSecondFoldVideo,
      aboutUsVideoTopParagraph: state.content.aboutUsVideoTopParagraph,
      aboutUsVideoBottomParagraph: state.content.aboutUsVideoBottomParagraph,
      aboutUsProcessTextHeader: state.content.aboutUsProcessTextHeader,
      aboutUsFourthFoldCtaText: state.content.aboutUsFourthFoldCtaText,
      aboutUsProcessTextStepOneHeader:
        state.content.aboutUsProcessTextStepOneHeader,
      aboutUsProcessTextStepOneDetails:
        state.content.aboutUsProcessTextStepOneDetails,
      aboutUsProcessTextStepTwoHeader:
        state.content.aboutUsProcessTextStepTwoHeader,
      aboutUsProcessTextStepTwoDetails:
        state.content.aboutUsProcessTextStepTwoDetails,
      aboutUsProcessTextStepThreeHeader:
        state.content.aboutUsProcessTextStepThreeHeader,
      aboutUsProcessTextStepThreeDetails:
        state.content.aboutUsProcessTextStepThreeDetails,
      aboutUsProcessTextStepFourHeader:
        state.content.aboutUsProcessTextStepFourHeader,
      aboutUsProcessTextStepFourDetails:
        state.content.aboutUsProcessTextStepFourDetails,
      aboutUsVideoClientsImages: state.content.aboutUsVideoClientsImages,
      aboutUsHeartTextHeader: state.content.aboutUsHeartTextHeader,
      aboutUsHeartTextParagraph: state.content.aboutUsHeartTextParagraph,
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(About);
