import React from "react";
import pt from "prop-types";

import { Flex } from "@rebass/grid";

import Grid from "../../../Grid";
import { SingleResult } from "./SingleResult";

export const Results = ({ searchResults, ctaText }) => {
  return (
    <Grid
      maxWidth={[320, 440, 740, 820, 900, 1100, 1100, 1100]}
      mt={[100, 100, 100, 100, 100, 100, 200]}
    >
      <Flex
        flexWrap="wrap"
        justifyContent={[
          "center",
          "center",
          "center",
          "center",
          "space-between",
        ]}
      >
        {searchResults.map((results) => {
          const { title, imgSrc, pathname, clientname } = results;

          return (
            <SingleResult
              title={title}
              ctaText={ctaText}
              imgSrc={imgSrc}
              pathname={pathname}
              text={clientname}
            />
          );
        })}
      </Flex>
    </Grid>
  );
};

Results.propTypes = {
  searchResults: pt.array.isRequired,
};
