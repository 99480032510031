import { GET_CONTENT } from '../constants'

const initialState = {
  content: {}
}

const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTENT:
      return {
        ...action.payload
      }
    default:
      return state
  }
}

export default contentReducer
