import styled from 'styled-components'
import { textAlign } from 'styled-system'

export const test = 'test'

export const BannerContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 300px 0;
  background-size: cover !important; 
  background-image: ${({ imgUrl }) => `url(${imgUrl})`};
  background-position: center;
`

BannerContainer.TextContainer = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  ${textAlign}
`

BannerContainer.HeroText = styled.h1`
  margin: 0 auto 25px auto;
  font-size: 50px;
  line-height: 1.1;
  font-family: "Sailec-Bold";
  color: #fff;
`

BannerContainer.SubText = styled.span`
  font-size: 0.9em;
  letter-spacing: 1px;
  color: #c7d9dd;
`

BannerContainer.SubText.Underline = styled.span`
  text-decoration: underline;
  font-size: 0.9em;
  letter-spacing: 1px;
`
