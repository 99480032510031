// const findContentValue = (content) => {
//   const textThing = []

//   content.content.forEach((singleContent) => {
//     if (singleContent.content) {
//       findContentValue(singleContent)
//       return
//     }
//     return singleContent.value
//   })
// }

export const contentProcessor =  (blogs, query) => {
  const separatedQueries = query.split(' ')

  const filteredBlogs = []

  blogs.forEach((blog) => {
    const isQueriedBlog = separatedQueries.some((singleQuery) => {
      return blog.fields.title.toLowerCase().indexOf(singleQuery.toLowerCase()) !== -1
    })
    if (isQueriedBlog) {
      filteredBlogs.push(blog)
    }
  })
  return filteredBlogs
}

export const addEllipses = (text, limit) => {
  const ellipses = '...'
  const slicedText = text.slice(0, limit)
  const lastWhitespace = slicedText.lastIndexOf(' ')
  return `${slicedText.slice(0, lastWhitespace)}${ellipses}`
}
