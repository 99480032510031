import React from "react";
import "../styled/filter.scss";
import { FilterButton } from "./styled";

export const Filter = ({ filterValue, setFilterValue, filterOptions }) => {
  return (
    <div className="filterBar">
      <div className="filter">
        {filterOptions.map((options) => {
          return (
            <FilterButton
              isActive={filterValue === options}
              onClick={() => {
                setFilterValue(options);
              }}
            >
              {options}
            </FilterButton>
          );
        })}
      </div>
    </div>
  );
};
