import styled from 'styled-components'
import { maxWidth } from 'styled-system'

export const test = 'test'

export const BlogParagraph = styled.div`
  ${maxWidth}
  margin: 10px auto;
`
BlogParagraph.Text = styled.p`
  font-size: 16px;
  margin: 0 50px;
`

BlogParagraph.Image = styled.img`
  margin: 0 auto;
  width: 100%;
  height: auto;
  display: block;
`

export const IframeContainer = styled.span`
padding-bottom: 56.25%; 
position: relative; 
display: block; 
width: 100%;

> iframe {
  height: 100%;
  width: 100%;
  position: absolute; 
  top: 0; 
  left: 0;
}`
