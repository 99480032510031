import React from "react";
import { PanelSearch } from "../common/panel-search";

import portfolios from "./list-of-portfolios";
import { getQuery } from "../../utils/get-query";

export const CaseStudiesSearch = () => {
  const query = getQuery("sq");
  const searchResults = portfolios.filter(
    (portfolio) =>
      portfolio.title.toLowerCase().includes(query) ||
      portfolio.clientname.toLowerCase().includes(query)
  );

  return (
    <PanelSearch
      searchResults={searchResults}
      ctaText="See case study"
      query={query}
    />
  );
};
