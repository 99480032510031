import React from "react";

import DemoReel2023 from "../../../resources/videos/DemoReel2023_WebsiteHero.mp4";

import Button from "../../common/Button";

const HeroTextWithHighlight = ({
  heroContent,
  homepageHeroHighlightedText,
}) => {
  const heroWithoutHighlightText = heroContent.replace(
    homepageHeroHighlightedText,
    "!@#$%"
  );
  const list = heroWithoutHighlightText.split("!@#$%");
  return (
    <>
      <font className="homepage-banner-header-text">
        {list[0]}
        <span className="homepage-banner-header-highlighted">
          {homepageHeroHighlightedText}
        </span>
        {list[1]}
      </font>
    </>
  );
};

export default (
  homepageVideo,
  heroContent,
  homepageHeroHighlightedText,
  homepageHeroSubtitleText,
  homepageHeroButtonText,
) => (
  <div className="homepage-banner">
    <div className="homepage-banner-header-container">
      <div>
        <h1
          className="homepage-banner-header"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <HeroTextWithHighlight
            heroContent={heroContent}
            homepageHeroHighlightedText={homepageHeroHighlightedText}
          />
          <font className="homepage-banner-header__subheader">
            {homepageHeroSubtitleText}.
          </font>
        </h1>
      </div>
      <div className="homepage-banner-links">
        <Button
          text={homepageHeroButtonText}
          isNewWindow
          href="https://wakefield.agency/contact-us"
        />
        {/* <BannerLinks mr={["12px", "12px", "12px", "12px", "26px"]}>
          <Link to="/portfolio">
            <span>Our Work</span>
          </Link>
        </BannerLinks>
        <BannerLinks>
          <Link to="/about#about-us-process">
            <span>Our Process</span>
          </Link>
        </BannerLinks> */}
      </div>
    </div>
    <video
      className="homepage-banner-video"
      playsInline
      autoPlay
      loop
      muted={true}
    >
      <source src={DemoReel2023} type="video/mp4" />
    </video>
    {/* <iframe
      title="homepage-video"
      src={`https://player.vimeo.com/video/${homepageVideo}?muted=1&autoplay=1&background=1`}
      width="100%"
      height="100%"
      webkitallowfullscreen
      mozallowfullscreen
      allow="autoplay"
      allowfullscreen
      playsinline
    ></iframe> */}
  </div>
);

{/* <iframe src="https://player.vimeo.com/video/886640793?h=89c4ff100e" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
<p><a href="https://vimeo.com/886640793">WF Demo Reel 2023</a> from <a href="https://vimeo.com/wakefieldagency">Wakefield Agency</a> on <a href="https://vimeo.com">Vimeo</a>.</p> */}