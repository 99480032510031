import React from "react";
import pt from "prop-types";
import moment from "moment";

import { Flex } from "@rebass/grid";

import { addEllipses } from "../blogContentProcessor";

import Grid from "../../common/Grid";
import SingleResult from "./SingleResult";

const Results = ({ filteredBlogs }) => {
  return (
    <Grid
      maxWidth={[320, 440, 740, 820, 900, 1100, 1100, 1100]}
      mt={[100, 100, 100, 100, 100, 100, 200]}
    >
      <Flex
        flexWrap="wrap"
        justifyContent={[
          "center",
          "center",
          "center",
          "center",
          "space-between",
        ]}
      >
        {filteredBlogs.map((blog) => {
          const {
            dateAndTime,
            blogPostNumber,
            title,
            bannerImage,
            content,
          } = blog.fields;

          const date = moment(dateAndTime).format("MMM Do YYYY");
          const text = content.content[0].content[0].value;
          const massagedText = text.length > 86 ? addEllipses(text, 86) : text;
          const massagedTitle =
            title.length > 26 ? addEllipses(title, 28) : title;
          const imageUrl = bannerImage.fields.file.url;

          return (
            <SingleResult
              date={date}
              blogPostNumber={blogPostNumber}
              title={massagedTitle}
              imageUrl={imageUrl}
              text={massagedText}
            />
          );
        })}
      </Flex>
    </Grid>
  );
};

Results.propTypes = {
  filteredBlogs: pt.array.isRequired,
};

export default Results;
