import React, { useState } from "react";
import pt from "prop-types";
import { useHistory } from "react-router-dom";
import { SearchInputContainer, SearchInput } from "./styled";
import searchIcon from "../../../../resources/images/icon-search.png";

export const SearchButton = ({ searchNamespace }) => {
  const history = useHistory();
  const [value, setValue] = useState("");
  return (
    <SearchInputContainer>
      <SearchInput
        img={searchIcon}
        type="text"
        className="wf-filter-input"
        placeholder="search"
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key.toLowerCase() !== "enter") {
            return;
          }
          history.push(`/${searchNamespace}-search?sq=${value}`);
        }}
        value={value}
        onBlur={(e) => {
          if (e.target.value === "") {
            return;
          }
          history.push(`/${searchNamespace}?sq=${value}`);
        }}
      />
    </SearchInputContainer>
  );
};

SearchButton.PropTypes = {
  searchNamespace: pt.string.isRequired,
};
