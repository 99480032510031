import React from 'react'

import { Box } from '@rebass/grid'
import Grid from '../common/Grid'

const ErrorPage = () => {
  return (
    <Grid>
      <Box>
        404
      </Box>
    </Grid>
  )
}

export default ErrorPage
