import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "./App.scss";
import NewsletterSnippet from "./components/common/NewsletterSnippet";

import AOS from "aos";
import "aos/dist/aos.css";

// Commented out as new GTM id is in place?
// https://wakefieldproductions.slack.com/archives/D06T021RN/p1688507469403039
// import ReactGA from "react-ga";

import store from "./store";
import theme from "./theme";

import About from "./components/About";
import BlogList from "./components/BlogList";
import BlogSearch from "./components/BlogSearch";
import Contact from "./components/Contact";
import DataRetriever from "./components/DataRetriever";
import ErrorPage from "./components/ErrorPage";
import Homepage from "./components/Homepage";
import Journal from "./components/Journal";
import PortfolioListing from "./components/Portfolio/Portfolio-Listing";
import {
  Portfolio,
} from "./components/Portfolio/portfolios";
import Team from "./components/Team";
import { CaseStudies } from "./components/case-studies";
import { CaseStudiesSearch } from "./components/case-studies/case-studies-search";
import Header from "./components/common/Header";
import ScrollToTop from "./components/util/ScrollToTop";

// import HomepageStage from './components/HomepageStage'
// import AboutStaging from './components/AboutStaging'

const cookieName = "wfNewsletter";

AOS.init({
  duration: 800,
  easing: "ease-out-sine",
  once: true,
});

const App = () => {
  const [state, setState] = useState({
    debouncer: null,
    hasSeenNewsLetter: false,
  });
  const [cookies] = useCookies([cookieName]);
  const cookieValue = parseInt(cookies[cookieName], 10) || 0;

  useEffect(() => {
    // ReactGA.initialize("UA-139792630-1");
    if (cookieValue < 2 && !window.location.pathname.includes('/case-studies')) {
      window.addEventListener("scroll", scrollListener);
    }
    return () => {
      if (cookieValue < 2) {
        window.addEventListener("scroll", scrollListener);
      }
    };
  }, []);

  const scrollListener = () => {
    if (state.debouncer !== null) {
      clearTimeout(state.debouncer);
    }
    setState({
      ...state,
      debouncer: setTimeout(() => {
        setTimeout(() => {
          setState({
            ...state,
            hasSeenNewsletter: false,
            showNewsletter: true,
          });
          window.removeEventListener("scroll", scrollListener);
        }, 500);
        // if (window.pageYOffset > 600) {
        //   // 860 400
        // }
      }, 10),
    });
  };

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div className="app-wrapper">
          <Router>
            <Switch>
              <ScrollToTop>
                <DataRetriever />
                <div className="App">
                  <Header />
                  <Route exact path="/" component={Homepage} />
                  {/* <Route exact path="/staging" component={HomepageStage} /> */}
                  <Route exact path="/team" component={Team} />
                  <Route exact path="/about" component={About} />
                  {/* <Route exact path="/staging/about" component={AboutStaging} /> */}
                  <Route exact path="/contact-us" component={Contact} />
                  <Route exact path="/blogs" component={BlogList} />
                  <Route exact path="/blogs/:blogId" component={Journal} />
                  <Route exact path="/journal-search" component={BlogSearch} />
                  <Route exact path="/404" component={ErrorPage} />
                  <Route exact path="/case-studies" component={CaseStudies} />
                  <Route
                    exact
                    path="/case-studies-search"
                    component={CaseStudiesSearch}
                  />
                  <Route exact path="/portfolio" component={PortfolioListing} />
                  <Route
                    exact
                    path="/case-studies/:study"
                    component={Portfolio}
                  />
                  {/* <Route
                    exact
                    path="/non-profits"
                    component={NonProfits}
                  /> */}
                </div>
              </ScrollToTop>
            </Switch>
          </Router>
          {state.showNewsletter &&
            !state.hasSeenNewsletter &&
            cookieValue < 2
            ? (
              <NewsletterSnippet
                setHasSeenNewsletter={() => {
                  setState({
                    hasSeenNewsletter: true,
                  });
                }}
              />
            ) : null}
        </div>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
