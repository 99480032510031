import {
  layout,
  space,
  style,
  maxWidth
} from 'styled-system'

import styled from 'styled-components'

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${space}
  ${layout}
  ${style}
  ${maxWidth}
`

Grid.defaultProps = {
  width: '100%',
  maxWidth: {
    xs: 'unset',
    s: 420,
    m: 592,
    l: 936,
    xl: 1176,
  },
  // px: {
  //   xs: '16px',
  //   s: 0,
  //   m: 0,
  //   xm: 0,
  //   l: 0,
  //   xl: 0,
  //   xxl: '24px'
  // },
  mx: 'auto'
}

export default Grid
