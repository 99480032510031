import { getClient } from "./getContent";

export const getBlogs = async (isReversOrder = false) => {
  const client = await getClient();
  const data = await client.getEntries({
    content_type: "blog",
    include: "4",
    order: `-fields.blogPostNumber`,
  });

  const { items } = data;
  return items;
};

export const getSingleBlog = async (blogId) => {
  const client = await getClient(); // calling again?
  const data = await client.getEntry({
    content_type: "blog",
    "fields.blogPostNumber": blogId,
  });
  const { items } = data;
  return items;
};

export const getSearchedBlogs = async (query) => {
  const client = await getClient();
  const data = await client.getEntries({
    content_type: "blog",
    order: `-fields.blogPostNumber`,
    "fields.title[match]": query,
  });

  const { items } = data;
  return items;
};
