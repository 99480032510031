import React from "react";
import { useHistory } from "react-router-dom";
import pt from "prop-types";

import { Flex, Box } from "@rebass/grid";

import {
  ResultContainer,
  ImageBox,
  TextContainer,
  Button,
  Text,
} from "./components";

export const SingleResult = ({ title, ctaText, pathname, imgSrc, text }) => {
  const history = useHistory();

  return (
    <ResultContainer
      width={["100%", "100%", "50%", "50%", "33%"]}
      mb={[80, 80, 80, 80, 80, 80, 200]}
      px={[25, 25, 25, 12.5, 12.5, 12.5, 25]}
      onClick={() => {
        history.push(pathname);
      }}
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <Box width="100%">
        <h2
          style={{
            fontSize: "1.5em",
            lineHeight: "1.5em",
            marginLeft: "0",
            marginRight: "0",
          }}
        >
          {title}
        </h2>
      </Box>
      <Box width={"100%"}>
        <ImageBox width={"100%"} height={[220, 275]} imgSrc={imgSrc} />
      </Box>
      <TextContainer my={[20]} width={"100%"}>
        <Text>{text}</Text>
      </TextContainer>
      <Flex>
        <Box>
          <Button>{ctaText}</Button>
        </Box>
      </Flex>
    </ResultContainer>
  );
};

SingleResult.propTypes = {
  title: pt.string.isRequired,
  ctaText: pt.string.isRequired,
  imgSrc: pt.string.isRequired,
  pathname: pt.string.isRequired,
  text: pt.string.isRequired,
};
