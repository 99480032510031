import styled from "styled-components";

export const SearchInputContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 15px;
  position: relative;
`;

export const SearchInput = styled.input`
  padding: 7px 35px 7px 15px;
  border-radius: 15px;
  background-color: #c7d9dd;
  transition: 0.2s all;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  font-size: 0.8em;
  font-family: "Sailec" sans-serif;
  width: 0;
  &:focus,
  &:active {
    width: 100%;
    outline: 0;
    opacity: 1;
    width: 100%;
  }
  background: #fafafa url(${({ img }) => img}) no-repeat center right;
`;

export const FilterButton = styled.button`
  outline: none;
  cursor: pointer;
  ${({ isActive }) => (isActive ? "&.active { color: #000; }" : "")};
  &:hover {
    color: #000;
  }
`;
