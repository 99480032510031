import styled from "styled-components";

export const StyledPanel = styled.div`
  display: flex;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)) no-repeat center;
  background-size: cover !important;
  padding-bottom: 0;
  width: 100%;
  height: 100% !important;
  display: flex;
  background-image: url('${({ imgSrc }) => {
    return imgSrc;
  }}');
`;
