export default [
    {
      portfolioID: 1,
      title: 'Delivering on Promises',
      clientUrl: '/osk',
      clientname: 'OSK Automotive',
      challenge: 'Wakefield had the challenge of helping an auto-parts company communicate its brand value with a video story. We needed to determine what was the core purpose of the company before we could create a story that would express the message it was trying to send.',
      solution: 'We distilled the essence of the brand in the desire of the company, which was delivering on its promises. Because the parts that the company was creating would help drivers, they were helping drivers deliver on their promises. This led us to creating the tagline “Helping you deliver on life’s greatest promises”. With this tagline in mind, we created a story of a father who is dedicated to keeping his word with his daughter.',
      results: 'What resulted was an emotional 3-minute film expressing the desire of a father to keep his promise with his daughter, associating the attributes of “perseverance”, “trustworthy”, and “caring” with the brand of OSK.',
      testimonial: 'Wakefield took the building blocks of our identity and values and crafted a story and message that strongly spoke to our ethos and essence. We were powerfully moved by the result of this video and it touched me deeply.',
      category: 'full',
      testimonialAuthor: 'George Liu, Founder, OSK Automotive',
      videoSrc: 'https://player.vimeo.com/video/294682726'
    },
    {
      portfolioID: 2,
      title: 'Not Too Soon',
      clientUrl: '/dog-quality',
      clientname: 'Dog Quality',
      challenge: 'How do we tell the story of a company that offers products that enhance the quality of life of senior dogs? The key was in the products, which showed the heart and intention of the owner of Dog Quality. She wanted to honour senior dogs for who they are in their owners lives, and to make it possible for their owners not to say goodbye too soon.',
      solution: 'With that in mind, we needed to show the bond between an owner and a dog, and the potential emotional pain from losing that dog. We also had project constraints to contend with, which meant we were only able to have one talent as the owner, and a dog actor. With all those factors in play, we decided to create a story that tells of a widower who lost his wife, but was being supported by his wife’s birthday gift, their dog. Unfortunately, this dog is also aging and is struggling to walk. Is it time to let go?',
      results: 'What resulted was a heartfelt, tear-jerker 2 min and 30 second film that generated over 1 million views on social that generated a lot of high quality engagement and comments from viewers who were touched.',
      testimonial: 'It was a wonderful working experience with the Wakefield Team. They are strong storytellers that really captured the essence of what we value and are about at Dog Quality. The video has touched many of our viewers in a deeply heartfelt way that has left a strong resonance and increased brand loyalty.',
      category: 'half',
      testimonialAuthor: 'Ann-Marie Fleming, Founder, Dog Quality',
      videoSrc: 'https://player.vimeo.com/video/294682157'
    }, 
    {
      portfolioID: 3,
      title: 'Here To Play',
      clientUrl: '/sport-ability',
      clientname: 'SportAbility',
      challenge: 'We were asked to craft a promotional video to raise awareness for sledgehockey. We did our research and realized the participants didn’t want to be inspiring, which is the first instinct for most of us watching. They wanted to get the message out there that they are here to play, just like other athletes. For us, that meant an empowering tone, where we could express some edge.',
      solution: 'To get the message across, we created a narrative that would be spoken by multiple athletes, showing the diversity of participation, and the tone we created was unapologetic, nonchalant, and down to earth. Visually, we wanted a dramatic, edgy look. To create that look, we utilized only two lights, causing a hard edge to illuminate the players. In post, we chose to make the colours colder, to make it more harsh, almost brooding. To bring the viewer into the experience, we constructed a rig that allowed us to film at ice level and skate alongside the players. Finally, we got a kick-ass score, quasi-Dark Knight, from ChanSounds.',
      results: 'What resulted was a dramatic, edgy and aggressive piece that generated 47,000 total views on Facebook.',
      testimonial: "What a fantastic team of storytellers at Wakefield. We continue to be blown away by their creativity and loved the whole working experience. We've received nothing but praise and positive comments regarding the video!",
      category: 'half',
      testimonialAuthor: 'Ross Macdonald, Executive Director, Sport Ability',
      videoSrc: 'https://player.vimeo.com/video/294681829'
    },
    {
      portfolioID: 4,
      title: 'Beyond Barriers',
      clientUrl: '/bcmos',
      clientname: 'BCMOS',
      challenge: 'We were so inspired by the story of BCMOS who would empower those with mobility barriers to experience nature. So how could we go about telling that story, in a way that would emphasize the action and the perseverance without words?',
      solution: 'We chose to forgo the traditional talking head and b-roll film for a 1-minute commercial style piece that would be more shareable on social. We decided to utilize sound and documentary-style visuals to communicate the struggle of the journey. We choose an upbeat piece of music to set the mood and show the triumph of the human spirit with the backdrop of nature.',
      results: 'What resulted was a short, 1-minute film that showcased the amazing opportunity that BCMOS was creating which generated 350,000 views in awareness.',
      testimonial: 'Wakefield’s strong command of storytelling brought forth a celebration of accessibility. As a result, their video production has raised awareness about our programs and our organization’s role in fostering an accessible society.',
      category: 'full',
      testimonialAuthor: 'Ruby Ng, Executive Director, Sam Sullivan Disability Foundation',
      videoSrc: 'https://player.vimeo.com/video/294681205'
    },
     {
      portfolioID: 5,
      title: 'A Global Movement',
      clientUrl: '/points-of-light',
      clientname: 'Points of Light',
      challenge: 'Three changemakers. Three different countries. How do we tell a unified story that would inspire and connect with a widespread audience to be the change they want to see?',
      solution: 'We needed to know our subjects stories first. We pre-interviewed the subjects to look for the common points in their answers to create a common theme, and choosing the appropriate music to evoke emotion while listening to their answers. When we crafted the narrative, we reverse-engineered the appropriate questions to prompt the answers that would form the foundation for the film. Finally, we took advantage of an event that was happening in Paris, that brought all these changemakers together, where we used set design and wardrobe to make an apartment in Paris, France to double for a place in India.',
      results: 'What resulted was a strong heartfelt story for the organization to use in communicating their heart and their cause. This video was posted on social and also used at conferences to be a point of inspiration for others to be changemakers wherever they lived.',
      testimonial: 'This video brings so much joy. It beautifully and inspirationally captures the dream we are building.',
      category: 'full',
      testimonialAuthor: 'Gared Price Jones, Senior Vice President, Points of Light',
      videoSrc: 'https://player.vimeo.com/video/294668369'
    },
    {
      portfolioID: 6,
      title: 'Blind Intensity',
      clientUrl: '/vancouver-goalball-club',
      clientname: 'Vancouver Goalball Club',
      challenge: 'When we first heard of goalball, we all asked, “What is goalball?” Our next reaction, after getting the answer, was “Wait, this is played by blind people?” The stuff available on Youtube wasn’t doing the sport justice, so we headed to an actual practice, and it was intensely impressive. It’s hard to imagine doing much with your eyes closed, much less hurling a 1.25 kg ball that could reach up to 60 km per hour towards goals you can’t see. Or blocking said ball with nothing guiding you other than a bell ringing inside the ball that’s heading towards at 60 km per hour.',
      solution: 'We didn’t want to just describe the action, we wanted the audience to feel like they were a part of it, so we chose different angles that would immerse the viewer or show the difficulty of the movements. We wanted to further immerse the viewer in the experience so we paid lots of attention to the sound of what was happening. Using an exciting music track, well captured in-game sound, dramatic lighting and stylized editing, we created a film that honoured the difficulty and intensity of the sport.',
      results: 'What resulted in an exciting film that garnered 51,000 views on Facebook which showed the world a different look at goalball.',
      testimonial: "We are so happy with how the video has turned out, and can't wait to share it with the world. It was such a pleasure working with Wakefield and their team!",
      category: 'half',
      testimonialAuthor: 'Lisa Odland, Tournament Coordinator, Vancouver Goalball Club',
      videoSrc: 'https://player.vimeo.com/video/294681649'
    },
     {
      portfolioID: 7,
      title: 'Respect',
      clientUrl: '/nemesis',
      clientname: 'Nemesis',
      challenge: 'There is SO much good food in our city. But even more importantly, there are good people making this food. For us, we wanted to communicate a chef’s ethos and philosophy, but keep it close to two minutes to make it more shareable and easy to watch.',
      solution: 'We decided to use just narration, instead of cutting to a talking head. Conducting an audio-only video, we got the main points of Chef Jacob’s cooking philosophy to tell the overall story of respecting ingredients and why he does what he does. We were given access to film in the kitchen at Nemesis, which is smaller than you would think, given the quality of food they output. Utilizing a lot of tighter shots, we showed the detail and intricacy of the different prep work and cooking to accompany Chef Jacob’s voiceover.',
      results: 'What resulted was a roughly 2 and a half minute film that told a concise story of Chef Jacob’s philosophy on cooking and food; also allowed us to have fun shooting something we love.',
      testimonial: "Wakefield curated an amazing video for us that was raw and very much reminiscent of Chef's Table. The shots were beautifully captured, illustrating the importance of our craft and why we do things the way to do at Nemesis, in the kitchen and behind the bar.",
      category: 'half',
      testimonialAuthor: 'Albert Tang, Co-Founder, Nemesis',
      videoSrc: 'https://player.vimeo.com/video/294682056'
    },
    {
      portfolioID: 8,
      title: 'Embrace Your Skin',
      clientUrl: '/racinne',
      clientname: 'Racinne',
      challenge: 'We love emotive stories, because they don’t just communicate a message, they communicate feelings. These feelings, when aligned with a brand can help differentiate that brand from the competition. We were approached by Racinne to create a story that would tell a story of acceptance.',
      solution: 'We considered the different ways we could approach this story and landed on demonstrating the value of embracing your skin. We found that skin isn’t just part of us, but that every mark tells a story. With that in mind, we told the story of a mother and a daughter who shares a scar because of a tragic event in the past.',
      results: 'What resulted was a touching film that generated nearly 85,000 views on Facebook and Youtube.',
      category: 'full',
      videoSrc: 'https://player.vimeo.com/video/294661547'
    }
];

