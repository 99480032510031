import styled from "styled-components";

export const PanelHeader = styled.h2`
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.2;
  color: #ed6436;
  font-size: 36px;
  @media only screen and (min-width: 768px) {
    font-size: 52px;
  }
`;

export const PanelSubHeader = styled.div`
  padding: 1rem 0;
  text-align: center;
  span {
    text-align: center;
    text-transform: capitalize;
    font-size: 0.8em;
    letter-spacing: 1px;
  }
`;
