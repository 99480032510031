/* eslint-disable prefer-destructuring */
// Start from 0 when doing styled-component props
const breakpoints = [
  '30em', // 480px,
  '40em', // 640px,
  '48em', // 768px, 
  '52em', // 832px
  '64em', // 1024px
  '80em', // 1280px
  '90em' // 1440px
]

// aliases
breakpoints.xs = 0
breakpoints.s = breakpoints[0]
breakpoints.m = breakpoints[1]
breakpoints.xm = breakpoints[2]
breakpoints.l = breakpoints[3]
breakpoints.xl = breakpoints[4]
breakpoints.xxl = breakpoints[5]

export default breakpoints
