import pt from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { getFooterContent } from "../../../selectors/content";

import ContactSection from "../ContactSection";
import "./styles.scss";

import LineVertical from "../LineVertical";

const Footer = ({ hasContactSection, hasLine, containerStyle, textStyle }) => {
  const footerContent = useSelector(getFooterContent);
  const { generalFooterTextLarge, generalFooterTextLink } = footerContent;
  return (
    <div
      className={`footer-contact-container wrapper ${hasContactSection &&
        "has-section"}`}
      style={containerStyle}
    >
      {hasLine ? (
        <LineVertical customStyle={{ top: "20%", marginTop: 0 }} />
      ) : null}
      <div className="footer-contact text-container">
        <div className="footer-contact-header" style={textStyle}>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            {generalFooterTextLarge}
          </h3>
        </div>
        <div
          className="footer-contact-text"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="400"
        >
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wakefield.agency/contact-us"
            >
              {generalFooterTextLink}
            </a>
          </p>
        </div>
      </div>
      {hasContactSection ? <ContactSection /> : null}
    </div>
  );
};

Footer.propTypes = {
  hasContactSection: pt.bool,
  hasLine: pt.bool,
  containerStyle: pt.object,
  textStyle: pt.oneOfType([pt.string, pt.node, pt.object]),
  textContent: pt.oneOfType([pt.string, pt.node, pt.object]),
  ctaText: pt.string,
};

Footer.defaultProps = {
  hasContactSection: false,
  hasLine: false,
  containerStyle: {},
  textStyle: {},
  textContent: "We would love to hear from you.",
  ctaText: "Let's Talk.",
};

export default Footer;
