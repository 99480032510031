import styled from "styled-components";

import { Box } from "@rebass/grid";

export default styled(Box)`
  z-index: 100;
  height: 24px;
  width: 23px;
  position: absolute;
  top: 15px;
  right: 15px;
  transform: rotate(45deg);
  cursor: pointer;
`;
