/* eslint-disable no-console */

/**
 * @property {NODE_ENV} - this env var is used to toggle between prod/dev to check if there should
 *   be verbosive logging, allow backdoor access, etc
 * @property {APP_ENV} - used to determine which deployed env currently is. This is whats used to import the right config file.
 */

/* eslint-disable import/no-dynamic-require */
import defaultConfig from "./default";

let env;
let envConfig;
try {
  env = process.env.NODE_ENV || "default";
  envConfig = require(`./${env}`).default;
} catch (e) {
  envConfig = {};
  if (e.code !== "MODULE_NOT_FOUND") {
    console.error(e);
  }
}

export default {
  env,
  ...defaultConfig,
  ...envConfig,
};
