import React, { useEffect } from "react";
import pt from "prop-types";

import { Flex, Box } from "@rebass/grid";

import colors from "../../../theme/colors";
import Grid from "../../common/Grid";
import LineVertical from "../../common/LineVertical";

import mine from "../images/icon-mine.svg";
import magic from "../images/icon-magic.svg";
import distill from "../images/icon-distill.svg";
import refine from "../images/icon-refine.svg";

import {
  Container,
  HeaderContainer,
  ContentContainer,
  ContentHeader,
  ContentDetails,
  MobileIcons,
} from "./components";

const Process = ({ processTextContent }) => {
  const {
    aboutUsProcessTextHeader,
    aboutUsProcessTextStepOneHeader,
    aboutUsProcessTextStepOneDetails,
    aboutUsProcessTextStepTwoHeader,
    aboutUsProcessTextStepTwoDetails,
    aboutUsProcessTextStepThreeHeader,
    aboutUsProcessTextStepThreeDetails,
    aboutUsProcessTextStepFourHeader,
    aboutUsProcessTextStepFourDetails,
  } = processTextContent;

  const contentDraw = {
    header: aboutUsProcessTextStepOneHeader,
    details: aboutUsProcessTextStepOneDetails,
    icon: mine,
  };

  const contentMessaging = {
    header: aboutUsProcessTextStepTwoHeader,
    details: aboutUsProcessTextStepTwoDetails,
    icon: distill,
  };

  const contentMagic = {
    header: aboutUsProcessTextStepThreeHeader,
    details: aboutUsProcessTextStepThreeDetails,
    icon: magic,
  };

  const contentResults = {
    header: aboutUsProcessTextStepFourHeader,
    details: aboutUsProcessTextStepFourDetails,
    icon: refine,
  };

  const contentOne = [contentDraw, contentMagic];
  const contentTwo = [contentMessaging, contentResults];
  const combinedContent = [
    contentDraw,
    contentMessaging,
    contentMagic,
    contentResults,
  ];

  useEffect(() => {
    const { hash } = window.location;
    if (hash !== "") {
      setTimeout(() => {
        window.location.href = `${hash}`;
      }, 250);
    }
  }, []);

  return (
    <Box id="about-us-process" backgroundColor={colors.themeGreen}>
      <Container maxWidth={["80%"]}>
        <LineVertical
          customStyle={{
            height: "168px",
            top: "-84px",
          }}
        />
        <HeaderContainer
          maxWidth={["unset", "unset", "unset", "unset", "55%"]}
          pt={["128px", "128px", "128px", "128px", "152px"]}
          pb={["96px", "96px", "96px", "96px", "108px"]}
        >
          <h3 style={{ fontSize: "2.2em", margin: 0 }}>
            {aboutUsProcessTextHeader}
          </h3>
        </HeaderContainer>
        <Box pb={["128px", "128px", "128px", "128px", "196px"]}>
          <Grid>
            <Flex
              maxWidth={["unset", "unset", "unset", "unset", "unset", 1020]}
              margin="0 auto"
              flexDirection={["row"]}
            >
              <ContentContainer
                display={["block", "block", "block", "block", "none"]}
              >
                <Flex flexDirection="column">
                  {combinedContent.map((content, index) => {
                    return (
                      <Box mb={index < combinedContent.length - 1 ? "98px" : 0}>
                        <Flex>
                          <MobileIcons
                            display={["none", "none", "block"]}
                            minWidth="112px"
                            height="112px"
                            mr="36px"
                            style={{
                              background: `url(${content.icon})`,
                            }}
                          />
                          <Flex flexDirection="column">
                            <Box mb="28px">
                              <ContentHeader>{content.header}</ContentHeader>
                            </Box>
                            <Box>
                              <ContentDetails>{content.details}</ContentDetails>
                            </Box>
                          </Flex>
                        </Flex>
                      </Box>
                    );
                  })}
                </Flex>
              </ContentContainer>
              <ContentContainer
                display={["none", "none", "none", "none", "block"]}
                mr={[0, 0, 0, 0, "28px"]}
                maxWidth="500px"
              >
                {contentOne.map((content, index) => {
                  return (
                    <Box mb={index === 0 ? "152px" : 0}>
                      <Flex>
                        <Box
                          minWidth="112px"
                          height="112px"
                          mr={["36px", "36px", "36px", "36px", "28px"]}
                          style={{
                            background: `url(${content.icon})`,
                          }}
                        />
                        <Flex flexDirection="column">
                          <Box mb={["36px"]}>
                            <ContentHeader>{content.header}</ContentHeader>
                          </Box>
                          <Box>
                            <ContentDetails>{content.details}</ContentDetails>
                          </Box>
                        </Flex>
                      </Flex>
                    </Box>
                  );
                })}
              </ContentContainer>
              <ContentContainer
                display={["none", "none", "none", "none", "block"]}
              >
                {contentTwo.map((content, index) => {
                  const mt = index === 0 ? [0, 0, 0, "112px"] : [];
                  return (
                    <Box mt={mt} mb={index === 0 ? "152px" : 0}>
                      <Flex>
                        <Box
                          minWidth="112px"
                          height="112px"
                          mr={["36px", "36px", "36px", "36px", "28px"]}
                          style={{
                            background: `url(${content.icon})`,
                          }}
                        />
                        <Flex flexDirection="column">
                          <Box mb={["36px"]}>
                            <ContentHeader>{content.header}</ContentHeader>
                          </Box>
                          <Box>
                            <ContentDetails>{content.details}</ContentDetails>
                          </Box>
                        </Flex>
                      </Flex>
                    </Box>
                  );
                })}
              </ContentContainer>
            </Flex>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

Process.propTypes = {
  processTextContent: pt.object.isRequired,
};

export default Process;
