import React from "react";
import pt from "prop-types";
import { Filter } from "./Filter";
import { SearchButton } from "./Search";

// TODO: Redux this?
export const FilterAndSearch = ({
  filterValue,
  setFilterValue,
  filterOptions,
  searchNamespace,
}) => {
  return (
    <div className="filterBar">
      <Filter
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        filterOptions={filterOptions}
      />
      <SearchButton searchNamespace={searchNamespace} />
    </div>
  );
};

FilterAndSearch.propTypes = {
  filterValue: pt.string.isRequired,
  setFilterValue: pt.func.isRequired,
  filterOptions: pt.arrayOf(pt.string).isRequired,
  searchNamespace: pt.string.isRequired,
};
