import React, { Component } from 'react';
import './styles.scss';

export default class Circles extends Component {
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
	}


	handleClick(e) {
		document.getElementsByClassName('circle-nav active')[0].classList.remove('active');
		e.target.classList.add('active');

		this.props.onClick && this.props.onClick(e);
	}

	render() {

		const { numberOfSlides, activeNumber } = this.props;

		let slides = [];
		
		for (let i = 0; i < numberOfSlides; i++) {
			slides.push({})
		}

		const theComponent = 
			slides.map((slide, index) => 
				index === activeNumber 
					? <div className="circle-nav active" id={index} onClick={this.handleClick}></div>
					: <div className="circle-nav" id={index} onClick={this.handleClick}></div>
			);

		return (
			<div className="circle-nav-container">
				{theComponent}
			</div>
		)
	}

}
