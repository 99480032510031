import React from 'react';
import './styles.scss';

export default props => {
    
    const navClassName = 
        typeof props.navClassName !== 'undefined'
            ? props.navClassName
            : 'header-container';
    
    const classNameBurgerIcon =
        typeof props.classNameBurgerIcon !== 'undefined'
            ? props.classNameBurgerIcon
            : 'header-menu-icon';

    return (
        <div className={navClassName}>
            <div className="header">
                <a href="/" id="logo"><div className="header-logo"></div></a>
                <div className="header-menu" onClick={props.onClick}>
                    <div className={`${classNameBurgerIcon} header-menu-icon-top`}></div>
                    <div className={`${classNameBurgerIcon} header-menu-icon-middle`}></div>
                    <div className={`${classNameBurgerIcon} header-menu-icon-bottom`}></div>
                </div>
            </div>
        </div>
    );
}
    