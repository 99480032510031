import styled from 'styled-components'
import {
  maxWidth,
  width,
  height,
  top,
  fontSize
} from 'styled-system'

import { Flex, Box } from '@rebass/grid'

const boxStyle = `
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
`

export const ModalBox = styled(Box)`
  ${boxStyle}
  ${top}
`

export const LightBox = styled(Box)`
  ${boxStyle}
  height: 1000vh;
  background-color: #000;
  opacity: 0.6;
`

// top: 50%;
// transform: translate(-50%, -50%);
// transform: translate(-50%, 0);
export const ModalContainer = styled(Flex)`
  position: fixed;
  ${top}
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  ${width}
  flex-direction: column;
  opacity: 1;
  background-color: ${({ theme }) => theme.colors.white};
`

export const ModalContent = styled(Flex)`
  background-color: #fff;
  ${maxWidth}
`

export const ModalHeader = styled(Box)`
  h3 {
    color: #000;
    ${fontSize}
    margin: 0;
  }
`

export const ModalLabel = styled(Box)`
  label {
    ${fontSize}
    font-weight: bold;
    letter-spacing: 1px;
  }
`

export const ModalInput = styled(Box)`
  input {
    border-bottom: 1px solid #000;
    ${width}
    ${fontSize}
    padding-left: 2px;
    background-color: #fff;
  }
  textarea {
    background-color: #fff;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`
