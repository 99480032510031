import React from 'react'
import pt from 'prop-types'
import Container from './container'

const IconClose = ({
  color
}) => {
  return (
    <Container>
      <svg viewBox="0 0 24 23">
        <path
          d="M10.62 0v10.34H0v2.323h10.62V23h2.76V12.663H24V10.34H13.38V0z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    </Container>
  )
}

IconClose.propTypes = {
  color: pt.string
}

IconClose.defaultProps = {
  color: '#ffffff'
}

export default IconClose
