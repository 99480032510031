import React from "react";
import { useDispatch } from "react-redux";

import { getHomepageContent } from "../../services/getContent";
import { saveContent } from "../../actions/content";

export default () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    const fetchContentData = async () => {
      const content = await getHomepageContent();
      dispatch(saveContent(content));
    };
    fetchContentData();
  }, []);

  return null;
};
