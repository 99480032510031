import React, { useState } from "react";
import pt from "prop-types";

import { Box } from "@rebass/grid";

import LineVertical from "../../../common/LineVertical";
import { PanelHeader, PanelSubHeader } from "./styled";
import { FilterAndSearch } from "../components/filter-and-search";

export const PanelList = ({
  List,
  filterOptions,
  headerText,
  value,
  handleChange,
  handleClick,
  handleSearch,
  handleBlogs,
}) => {
  const [filterValue, setFilterValue] = useState(filterOptions[0]);
  return (
    <Box>
      <div className="blog-header wrapper">
        <LineVertical />
        <PanelHeader data-aos="fade-up" data-aos-duration="1000">
          {headerText}
        </PanelHeader>
        <PanelSubHeader
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="400"
        >
          <span>What's been cooking</span>
        </PanelSubHeader>
      </div>
      <Box mx={[30, 30, 30, 90]}>
        <FilterAndSearch
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          filterOptions={filterOptions}
          searchNamespace="case-studies"
        />
      </Box>
      <div>
        <List filterOption={filterValue} />
      </div>
      {filterValue === "All" ? (
        <div className="buttonWrap">
          <button className="wf-contact-submit" onClick={() => handleBlogs()}>
            Show more
          </button>
        </div>
      ) : null}
    </Box>
  );
};

PanelList.propTypes = {
  List: pt.node.isRequired,
  headerText: pt.string.isRequired,
  searchValue: pt.func.isRequired,
  handleChange: pt.func.isRequired,
  handleClick: pt.func.isRequired,
  handleSearch: pt.func.isRequired,
};
