// Use single quotes, not double quotes
// Don't need ; anymore
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Isotope from "isotope-layout";
import { Box } from "@rebass/grid";

import colors from "../../theme/colors";

import { createBlockyNav } from "../../actions";
import { initialState } from "../../reducers";
import { getBlogs, getSearchedBlogs } from "../../services/getContentfulBlog";
import { saveBlogs } from "../../actions/blogs";
import { getAllBlogs } from "../../selectors/blogs";
import Subscribe from "../common/Subscribe";
import ContactSection from "../common/ContactSection";
import "./styles/index.scss";

import View from "./View";

const BlogList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const allBlogs = useSelector(getAllBlogs);
  const [blogCount, setBlogCount] = useState({
    All: 6,
    Storytelling: 3,
    Projects: 3,
  });
  const [blogs, setBlogs] = useState([]);
  const [iso, setIso] = useState(null);
  const [filterValue, setFilterValue] = useState("All");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const headerWrapperStyle = {
      height: "60px",
    };

    const classNameNavBar = "header-container header-container-solid";
    const classNameBurgerIcon = "header-menu-icon header-menu-icon-black";
    const shouldChangeNav = false;

    dispatch(
      createBlockyNav({
        shouldChangeNav,
        headerWrapperStyle,
        classNameNavBar,
        classNameBurgerIcon,
      })
    );

    const fetchData = async () => {
      const returnedBlogs = await getBlogs();
      dispatch(saveBlogs(returnedBlogs));

      if (iso) {
        iso.arrange({ filter: `.${filterValue}` });
      }
    };
    fetchData();

    return () => {
      dispatch(createBlockyNav(initialState));
    };
  }, []);

  useEffect(
    () => {
      setBlogs(allBlogs.slice(0, blogCount[filterValue]));
    },
    [allBlogs]
  );

  useEffect(
    () => {
      setIso(
        new Isotope(".grid", {
          itemSelector: ".element-item",
          layoutMode: "fitRows",
        })
      );
    },
    [blogs]
  );

  useEffect(
    () => {
      if (iso) {
        if (filterValue === "All") {
          iso.arrange({ filter: "*" });
          setBlogs(allBlogs.slice(0, blogCount[filterValue]));
        } else {
          iso.arrange({ filter: `.${filterValue}` });
        }
      }
    },
    [filterValue]
  );

  useEffect(
    () => {
      if (filterValue === "All") {
        setBlogs(allBlogs.slice(0, blogCount[filterValue]));
      } else {
        const filtered = allBlogs.filter(
          (blog) => blog.fields.category === filterValue
        );
        setBlogs(filtered.slice(0, blogCount[filterValue]));
      }
    },
    [blogCount]
  );

  const _handleClick = (e, link) => {
    e.preventDefault();
    history.push(link);
  };

  const _handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const _handleSearch = async (e) => {
    e.preventDefault();
    // We must have 2 different lists of blogs. One for searched, one for all
    const searchedBlogs = await getSearchedBlogs(searchValue);
    dispatch(saveBlogs(searchedBlogs));
    history.push(`/journal-search?bsq=${searchValue}`);
  };

  const _handleBlogs = () => {
    if (iso) {
      iso.arrange({ filter: "*" });
    }

    setBlogCount({
      All: blogCount.All + 3,
      Storytelling: blogCount.Storytelling + 3,
      Projects: blogCount.Projects + 3,
    });
  };

  return (
    <div data-aos="fade" data-aos-duration="1000">
      <View
        blogs={blogs}
        value={searchValue}
        handleClick={_handleClick}
        handleChange={_handleChange}
        handleSearch={_handleSearch}
        handleBlogs={_handleBlogs}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
      />
      <Box>
        <Subscribe color="black" padding={{ py: [100], px: [] }} />
      </Box>
      <Box>
        <Box>
          <ContactSection
            containerStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "52px 0",
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default BlogList;
