import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import PortfolioTemplate from '../Portfolio-Template';
import { connect } from "react-redux";
import { createBlockyNav, updateStateForPortfolios } from '../../../actions';

import SideNav from '../../common/SideNav';

import portfolios from '../list-of-portfolios';

import './styles/index.scss';

class PortfolioListing extends Component {
	constructor(props) {
		super(props);
		
		const HEIGHT_OF_NAV = 90;
		const THRESHOLD_FOR_BOTTOM = 280;

		const listenForUnstickyNav = () => {
			const isBottomAtTop = document.getElementsByClassName('portfolio-description-container')[0].getBoundingClientRect().bottom <= THRESHOLD_FOR_BOTTOM;
			const isSideMenuBackToDefaultPosition = document.getElementsByClassName('portfolio-description-container')[0].getBoundingClientRect().top >= HEIGHT_OF_NAV;
			
			if (isBottomAtTop || isSideMenuBackToDefaultPosition) {
				document.getElementsByClassName('side-menu')[0].classList.remove('sticky');
				document.getElementsByClassName('side-menu')[1].classList.remove('sticky');
			}

		}

		// Stick Prev/Nav Menu when description container's top goes above the page
		const listenForStickyNav = () => {
			
			const hasHitTop = document.getElementsByClassName('side-menu')[0].getBoundingClientRect().top <= HEIGHT_OF_NAV;
			const isNotSticky = 
				document.getElementsByClassName('side-menu')[0].classList.value.indexOf('sticky') === -1
				&& document.getElementsByClassName('side-menu')[1].classList.value.indexOf('sticky') === -1

			if (hasHitTop && isNotSticky) {
				
				document.getElementsByClassName('side-menu')[0].classList.add('sticky');
				document.getElementsByClassName('side-menu')[1].classList.add('sticky');
				window.addEventListener('scroll', listenForUnstickyNav);
			}

			// For animation 
			// !!!THIS SHOULD NOT BE IN LISTENFORSTICKYNAV FUNCTION. MUST FIGURE OUT HOW TO ADD SCROLL EVENTS TO COMPONENTS DISCRETELY!!!

			// FOR DESCRIPTION SECTION
			if (window.pageYOffset > 60) {
				document.getElementsByClassName('portfolio-description-container')[0].classList.value.indexOf('shown') === -1 
				&& document.getElementsByClassName('portfolio-description-container')[0].classList.add('shown');
			}

			// FOR TESTIMONIAL SECTION
			const windowHeight = window.innerHeight;
			const bottomOfDescription = document.getElementsByClassName('portfolio-description-container')[0].getBoundingClientRect().bottom;
			const isTestimonialShown = document.getElementsByClassName('portfolio-testimonial')[0].classList.value.indexOf('shown') > -1;
			if (bottomOfDescription <= windowHeight && !isTestimonialShown) {
				document.getElementsByClassName('portfolio-testimonial')[0].classList.add('shown');
			}

			// FOR BOTTOM "NEXT" SECTION
			const bottomOfTestimonial = document.getElementsByClassName('portfolio-testimonial')[0].getBoundingClientRect().bottom;
			const isNextContainerShown = document.getElementsByClassName('portfolio-next-container')[0].classList.value.indexOf('shown') > -1;
			if (bottomOfTestimonial <= windowHeight && !isNextContainerShown) {
				document.getElementsByClassName('portfolio-next-container')[0].classList.add('shown');
			}
		
		}
		
		this.state = {
			showIndividual: this.props.navigateToPortfolio,
			portfolios,
			portfolioLength: portfolios.length,
			individualType: this.props.individualType || {},
			listenForUnstickyNav,
			listenForStickyNav
		}

		this.handleClick = this.handleClick.bind(this);
	}

  	handleClick(e) { // REFACTOR

			//Remove classnames when component is updated so that animation can take place again. Should refactor
			const removeClassNames = new Promise(resolve => {
					[].forEach.call(document.querySelectorAll('.aos-animate'), el => el.classList.remove('aos-animate'));
					[].forEach.call(document.querySelectorAll('.shown'), el => el.classList.remove('shown'));

					resolve();
			});

			removeClassNames.then()
			
	}

	componentDidUpdate() {
		
		const { showIndividual, listenForStickyNav, listenForUnstickyNav } = this.state;
		
		// Add listener when showing individual portfolio
		if (showIndividual) {
			window.addEventListener('scroll', listenForStickyNav);
		} else {
			window.removeEventListener('scroll', listenForStickyNav) && window.removeEventListener('scroll', listenForUnstickyNav);
		}
	}

	componentDidMount() {

		// This is to change nav to solid on portfolio pages
		const headerWrapperStyle = {
			height: '60px'
		};

		const classNameNavBar = 'header-container header-container-solid'
		const classNameBurgerIcon = 'header-menu-icon header-menu-icon-black';
		const shouldChangeNav = false;

		this.props.createBlockyNav({ 
			shouldChangeNav,
			headerWrapperStyle,
			classNameNavBar,
			classNameBurgerIcon
		});

		// Temp workaround for users when clicking back button to go back to portfolio listings
		this.props.updateStateForPortfolios({
			isListings: true
		});

		this.props.history.push('/portfolio');

		this.originalPopState = window.onpopstate;

		window.onpopstate = () => {
            this.setState({
                showIndividual: false
            });
        }
	}

	componentWillUnmount() {
		window.onpopstate = this.originalPopState;
		const { listenForStickyNav, listenForUnstickyNav } = this.state;
	  
		// Remove scroll listeners when portfolio component is unmounted
		window.removeEventListener('scroll', listenForStickyNav);
		window.removeEventListener('scroll', listenForUnstickyNav);

		const headerWrapperStyle = {
			height: '0'
		};

		const classNameNavBar = 'header-container'
		const classNameBurgerIcon = 'header-menu-icon';
		const shouldChangeNav = true;

		this.props.createBlockyNav({ 
			shouldChangeNav,
			headerWrapperStyle,
			classNameNavBar,
			classNameBurgerIcon
		});
	}

  	render() {
    	const { portfolios } = this.state;
			
			// Array of PortfolioTemplate Component
			const portfolioContent =
				<div data-aos="fade" data-aos-duration="1000" >
					<div className="portfolios-all">
					<SideNav 
						title="PORTFOLIO" 
						initialHeight={150}
						parentClassName="portfolios-all"
						containerStyle={{ 'top': '0',  }}
						isTop={true}
						/>
					{
						portfolios.map((portfolio, index) => {
							
							const linkStyle = 
								index === 1 || index === 2 || index === 5 || index === 6
								? {
									display: 'block',
									width: '50%',
									height: ' 100%',
									padding: 0,
									margin: 0,
									border: 'none',
								}
								: {
									display: 'block',
									width: '100%',
									height: '100%',
									padding: 0,
									margin: 0,
									border: 'none',
								}

							return (
								<Link 
									to={`/portfolio${portfolio.clientUrl}`} 
									style={linkStyle}
								>
									<PortfolioTemplate
										index={index}
										key={portfolio.portfolioID}
										title={portfolio.title}
										category={portfolio.category}
										clientName={portfolio.clientname}
										portfolioID={portfolio.portfolioID}
										handleClick={this.handleClick}
									/>
								</Link>
							)
						})
					}
					</div>
				</div>;

		return (
			<section>
				{portfolioContent}
			</section>
		);
	}
}

const mapStateToProps = state => ({
	navigateToPortfolio: state.main.navigateToPortfolio,
	individualType: state.main.individualType
});

const mapDispatchToProps = dispatch => {
	return {
		createBlockyNav: payload => dispatch(createBlockyNav(payload)),
		updateStateForPortfolios: payload => dispatch(updateStateForPortfolios(payload))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioListing);
