import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box } from "@rebass/grid";

import colors from "../../theme/colors";

import { getBlogs } from "../../services/getContentfulBlog";
import { getAllBlogs } from "../../selectors/blogs";
import { saveBlogs } from "../../actions/blogs";

import { createBlockyNav } from "../../actions";
import { initialState } from "../../reducers";

import { contentProcessor } from "./blogContentProcessor";

import Subscribe from "../common/Subscribe";

import Query from "./Query";
import Results from "./Results";

const BlogSearch = () => {
  const dispatch = useDispatch();
  const searchParams = window.location.search;
  const params = new URLSearchParams(searchParams);
  const query = params.get("bsq") || "";
  const blogs = useSelector(getAllBlogs);
  const filteredBlogs = query === "" ? blogs : contentProcessor(blogs, query);

  useEffect(
    () => {
      const headerWrapperStyle = {
        height: "60px",
      };

      const classNameNavBar = "header-container header-container-solid";
      const classNameBurgerIcon = "header-menu-icon header-menu-icon-black";
      const shouldChangeNav = false;

      dispatch(
        createBlockyNav({
          shouldChangeNav,
          headerWrapperStyle,
          classNameNavBar,
          classNameBurgerIcon,
        })
      );

      if (blogs.length < 1) {
        const fetchData = async () => {
          const returnedBlogs = await getBlogs();
          dispatch(saveBlogs(returnedBlogs));
        };
        fetchData();
      }

      return () => {
        dispatch(createBlockyNav(initialState));
      };
    },
    [blogs]
  );

  return (
    <Box>
      {blogs.length > 0 ? (
        <>
          <Query query={query} />
          <Results filteredBlogs={filteredBlogs} />
          <Subscribe backgroundColor={colors.themeGreen} color="#fff" />
        </>
      ) : null}
    </Box>
  );
};

export default BlogSearch;
