import styled from "styled-components";

export const test = "test";

export const BackButton = styled.span`
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: bold;
  &::before {
    content: "←";
    margin-right: 3px;
    font-weight: bold;
    transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover::before {
    margin-right: 6px;
  }
`;

export const NextButton = styled.span`
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: bold;
  &::after {
    content: "→";
    margin-left: 3px;
    font-weight: bold;
    transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover::after {
    margin-left: 6px;
  }
`;
