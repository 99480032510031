import pt from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getAllBlogs } from "../../../selectors/blogs";

import { Box, Flex } from "@rebass/grid";

import { BackButton, NextButton } from "./components";

// const sorter = (firstNum, secondNum) => firstNum - secondNum

const BlogNavigation = ({ blogPostNumber }) => {
  const history = useHistory();
  const blogs = useSelector(getAllBlogs);

  const sortedBlogs = blogs.sort((firstBlog, secondBlog) => {
    return firstBlog.fields.blogPostNumber - secondBlog.fields.blogPostNumber;
  });

  const blogInfo = {};
  sortedBlogs.forEach((blog, index) => {
    if (blog.fields.blogPostNumber === blogPostNumber) {
      if (index === 0) {
        const lastBlogIndex = blogs.length - 1;
        blogInfo.prev = blogs[lastBlogIndex].fields.blogPostNumber;
        blogInfo.next = blogs[index + 1].fields.blogPostNumber;
      } else if (index === blogs.length - 1) {
        blogInfo.prev = blogs[index - 1].fields.blogPostNumber;
        blogInfo.next = blogs[0].fields.blogPostNumber;
      } else {
        blogInfo.prev = blogs[index - 1].fields.blogPostNumber;
        blogInfo.next = blogs[index + 1].fields.blogPostNumber;
      }
      blogInfo.current = blog.fields.blogPostNumber;
    }
  });

  const _moveBack = () => {
    history.push(`/blogs/${blogInfo.prev}`);
  };

  const _moveForward = () => {
    history.push(`/blogs/${blogInfo.next}`);
  };

  return (
    <Flex justifyContent="space-between">
      <Box onClick={_moveBack}>
        <BackButton>BACK</BackButton>
      </Box>
      <Box onClick={_moveForward}>
        <NextButton>NEXT ARTICLE</NextButton>
      </Box>
    </Flex>
  );
};

BlogNavigation.propTypes = {
  blogPostNumber: pt.number.isRequired,
};

export default BlogNavigation;
