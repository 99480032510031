import React from 'react'
import pt from 'prop-types'

const Paragraph = ({ children, styleProps }) => {
  return (
    <p
      style={{
        fontSize: '1em',
        lineHeight: '2em',
        fontWeight: styleProps.bold ? 'bold' : 'normal',
        fontStyle: styleProps.italic ? 'italic' : 'normal',
        textDecoration: styleProps.underline ? 'underline' : 'none'
      }}
    >
      {children}
    </p>
  )
}

Paragraph.propTypes = {
  children: pt.oneOfType([
    pt.node,
    pt.object,
  ]).isRequired,
  styleProps: pt.object
}

Paragraph.defaultProps = {
  styleProps: {}
}

export default Paragraph