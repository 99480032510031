import React from "react";

import colors from "../../../theme/colors";
import "./styles.scss";

const linkSections = [
  {
    header: "ABOUT",
    links: [
      {
        name: "The Process",
        href: "/about",
      },
      {
        name: "Our Team",
        href: "/team",
      },
    ],
  },
  {
    header: "SOCIAL",
    links: [
      {
        name: "LinkedIn",
        href: "https://www.linkedin.com/company/wakefieldagency",
      },
      {
        name: "BCorp",
        href:
          "https://www.bcorporation.net/en-us/find-a-b-corp/company/wakefield-agency",
      },
      // Removed as requested on Jan 2023
      // {
      //   name: "Storybrand",
      //   href: "https://marketingmadesimple.com/Nouver-Cheung",
      // },
    ],
  },
  {
    header: "ENGAGE",
    links: [
      {
        name: "Contact Us",
        href: "/contact-us",
      },
    ],
  },
];

export const Footer2022 = () => {
  const date = new Date();
  const YEAR = date.getFullYear();
  return (
    <div
      data-aos="fade-up"
      className="footer-2022__container"
      style={{ backgroundColor: colors.themeGreen }}
    >
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
        <a href="/" alt="homepage">
          <div className="footer-2022-logo" />
          <p className="footer-2022-logo-text">Wakefield Agency {YEAR}</p>
        </a>
      </div>
      <div
        className="footer-2022-right"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        {linkSections.map((section) => {
          return (
            <div className="footer-2022-link-container" key={section.header}>
              <p className="footer-2022-link-header">{section.header}</p>
              {section.links.map((link) => {
                return (
                  <a
                    className="footer-2022-link-text"
                    href={link.href}
                    alt={link.href}
                    key={link.href}
                  >
                    {link.name}
                  </a>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
