import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

export default (props) => (
  <div className="nav-menu" data-aos="fade-up" data-aos-duration="400">
    <div className="nav-menu-list-container">
      <ul className="nav-menu-list">
        <li>
          <Link
            className="nav-menu-links nav-menu-home"
            onClick={props.onClick}
            onMouseEnter={props.handleHoverOfMenu}
            to="/"
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            className="nav-menu-links nav-menu-about"
            onClick={props.onClick}
            onMouseEnter={props.handleHoverOfMenu}
            to="/about"
          >
            About
          </Link>
        </li>
        <li>
          <Link
            className="nav-menu-links nav-menu-portfolios"
            onClick={props.onClick}
            onMouseEnter={props.handleHoverOfMenu}
            to="/case-studies"
          >
            Case Studies
          </Link>
        </li>
        <li>
          <Link
            className="nav-menu-links nav-menu-team"
            onClick={props.onClick}
            onMouseEnter={props.handleHoverOfMenu}
            to="/team"
          >
            Team
          </Link>
        </li>
        <li>
          <Link
            className="nav-menu-links nav-menu-contact"
            onClick={props.onClick}
            onMouseEnter={props.handleHoverOfMenu}
            to="/blogs"
          >
            Blogs
          </Link>
        </li>
        <li>
          <Link
            className="nav-menu-links nav-menu-contact"
            onClick={props.onClick}
            onMouseEnter={props.handleHoverOfMenu}
            to="/contact-us"
          >
            Contact
          </Link>
        </li>
      </ul>
    </div>
    <div className="nav-menu-close" onClick={props.onClick}>
      <span>&#10005;</span>
    </div>
  </div>
);
