import { createClient } from "contentful";
import config from "../config";

const { spaceID, contentAPI, content } = config;

export const getClient = async () => {
  const client = await createClient({
    space: spaceID,
    accessToken: contentAPI,
  });
  return client;
};

export const getHomepageContent = async () => {
  const client = await getClient();
  const data = await client.getEntry(content.homepageContentId);

  const massagedData = data.fields;
  return massagedData;
};

export const getAllCaseStudes = async () => {
  const client = await getClient();

  const listOfCaseStudies = await client.getEntries({
    content_type: "caseStudies",
    order: "fields.id",
  });

  return listOfCaseStudies.items.map((caseStudyItem) => {
    return {
      ...caseStudyItem.fields,
    };
  });
};

// export const getCaseStudy = async () => {
//   const client = await getClient();

// }
