import React, { Component } from "react";
import SideNav from "../../common/SideNav";

import portfolios from "../../Portfolio/list-of-portfolios";
import Button from "../../common/Button";

import "./styles.scss";

class ValueStatement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageToShow: 0,
      intervalTime: 5000,
    };

    this.loopThroughImages = this.loopThroughImages.bind(this);
    this.cancelLoop = this.cancelLoop.bind(this);
    this.continueLoop = this.continueLoop.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const id = e.target.id >> 0;

    // Build necessary object to pass in to store
    const nextPortfolio =
      portfolios[id].portfolioID + 1 < portfolios.length
        ? portfolios[id].portfolioID + 1
        : 1;

    const individualType = {
      portfolioID: portfolios[id].portfolioID,
      title: portfolios[id].title,
      clientname: portfolios[id].clientname,
      challenge: portfolios[id].challenge,
      solution: portfolios[id].solution,
      results: portfolios[id].results,
      testimonial: portfolios[id].testimonial,
      testimonialAuthor: portfolios[id].testimonialAuthor,
      videoSrc: portfolios[id].videoSrc,
      nextPortfolio,
    };

    this.props.navigateToPortfolios({
      individualType,
    });

    if (individualType.clientname === "OSK Automotive") {
      window.location.href = "/case-studies/osk";
    }

    if (individualType.clientname === "Racinne") {
      window.location.href = "/case-studies/racinne";
    }

    if (individualType.clientname === "Points of Light") {
      window.location.href = "/case-studies/points-of-light";
    }
  }

  loopThroughImages(intervalTime = this.state.intervalTime) {
    this.imageLooper = setInterval(() => {
      // Determine if count should increment or set back to 1
      // const imageToShow = this.state.imageToShow < 2 ? this.state.imageToShow + 1 : 0;
      let imageToShow = 0;

      // Choose specific images
      if (this.state.imageToShow === 0) {
        imageToShow = 7;
      }
      if (this.state.imageToShow === 7) {
        imageToShow = 4;
      }

      this.setState({
        imageToShow,
      });
    }, intervalTime);
  }

  cancelLoop() {
    clearInterval(this.imageLooper);
  }

  // Call function to loop through images
  continueLoop() {
    this.loopThroughImages();
  }

  // Call function to loop through images
  componentDidMount() {
    this.loopThroughImages();
  }

  render() {
    const { imageToShow } = this.state;
    const { homepageVisionTextHeader, homepageVisionTextBody, homepageVisionButton, homepageVisionButtonLink } = this.props;
    console.log(homepageVisionButton, homepageVisionButtonLink)
    return (
      <section className="container-full value-statement-container">
        <SideNav
          title="Our Vision"
          initialHeight={0}
          parentClassName="value-statement-container"
        />
        <div className="wf-preload-image">
          <div className="one" />
          <div className="two" />
          <div className="three" />
        </div>
        <div
          className={`half-width half-width-left value-statement-image value-statement-image-${imageToShow}`}
          id={imageToShow}
          onMouseEnter={this.cancelLoop}
          onMouseLeave={this.continueLoop}
          onClick={this.handleClick}
        >
          <div>
            <h3>{portfolios[imageToShow].title}</h3>
            <h4>{portfolios[imageToShow].clientname}</h4>
          </div>
        </div>
        <div className="half-width half-width-right value-statement-content">
          <div>
            <div>
              <h2
                style={{ marginBottom: "20px" }}
                className="Sailec-Bold"
                data-aos="fade"
                data-aos-duration="500"
              >
                {homepageVisionTextHeader}
              </h2>
              {homepageVisionTextBody !== undefined
                ? homepageVisionTextBody.map((text) => {
                  return (
                    <p
                      className="value-statement-text"
                      data-aos="fade"
                      data-aos-duration="250"
                      data-aos-delay="200"
                      key={text}
                    >
                      {text}
                    </p>
                  );
                })
                : null}
              <div style={{ margin: "24px 20px 20px 20px" }}>
                <Button
                  text={homepageVisionButton}
                  isNewWindow
                  href={homepageVisionButtonLink}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ValueStatement;
