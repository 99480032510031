import React from "react";
import { useSelector } from "react-redux";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import { getContactContent } from "../../selectors/content";

import SideNav from "../common/SideNav";

import "./styles/index.scss";

import config from "../../config";
// import Footer from "../common/Footer";
import { Footer2022 } from "../common/Footer2022";
import { FooterConsultation } from "../common/footer-consultation";

const { MAILCHIMP_SNIPPET } = config;

const CustomForm = ({ status, message, onValidated }) => {
  // const LINEHEIGHT = 18;
  // const DEFAULSCROLLHEIGHT = 22;
  let name, email, phone, website, msg;

  // Submission to mailchimp db
  const submit = () => {
    onValidated({
      NAME: name.value,
      EMAIL: email.value,
      PHONE: phone.value,
      WEBSITE: website.value,
      MESSAGE: msg.value,
    });
  };

  // Toggle focus toggles classname of label
  const toggleFocus = (e) => {
    e.target.nextSibling.className.indexOf("input-focused") > -1 &&
    !e.target.value.length
      ? (e.target.nextSibling.className = "")
      : (e.target.nextSibling.className = "input-focused");
  };

  const handleChange = (e) => {
    // const newRows = ~~((e.target.scrollHeight - DEFAULSCROLLHEIGHT) / LINEHEIGHT);
    // e.target.rows = newRows;
  };

  // Form JSX
  return (
    // eslint-disable-next-line no-script-url
    <form action="javascript:void(0);" method="get">
      <div className="wf-form" data-aos="fade" data-aos-duration="200">
        {status === "sending" && (
          <div style={{ color: "blue" }}>sending...</div>
        )}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <div className="wf-form-section wf-form-section-inline">
          <input
            style={{ fontSize: "1em", paddingTop: 20 }}
            ref={(node) => (name = node)}
            name="name"
            type="text"
            onClick={toggleFocus}
            onBlur={toggleFocus}
          />
          <label htmlFor="name">NAME</label>
        </div>
        <div className="wf-form-section wf-form-section-inline">
          <input
            style={{ fontSize: "1em", paddingTop: 20 }}
            ref={(node) => (email = node)}
            name="email"
            type="email"
            onClick={toggleFocus}
            onBlur={toggleFocus}
          />
          <label htmlFor="email">EMAIL</label>
        </div>
        <div className="wf-form-section wf-form-section-inline">
          <input
            style={{ fontSize: "1em", paddingTop: 20 }}
            ref={(node) => (phone = node)}
            name="phone"
            type="phone"
            onClick={toggleFocus}
            onBlur={toggleFocus}
          />
          <label htmlFor="phone">PHONE</label>
        </div>
        <div className="wf-form-section wf-form-section-inline">
          <input
            style={{ fontSize: "1em", paddingTop: 20 }}
            ref={(node) => (website = node)}
            name="website"
            type="website"
            onClick={toggleFocus}
            onBlur={toggleFocus}
          />
          <label htmlFor="website">WEBSITE</label>
        </div>
        <div className="wf-form-section wf-form-section-block">
          <textarea
            style={{ fontSize: "1em", paddingTop: 20 }}
            ref={(node) => (msg = node)}
            name="msg"
            type="text"
            onClick={toggleFocus}
            onBlur={toggleFocus}
            rows={5}
            onChange={handleChange}
          />
          <label htmlFor="msg">MESSAGE</label>
        </div>
        <button className="wf-contact-submit" onClick={submit}>
          Submit
        </button>
      </div>
    </form>
  );
};

const Contact = () => {
  const contactContent = useSelector(getContactContent);
  const [url] = React.useState(MAILCHIMP_SNIPPET);
  const { contactPageTextHeader, contactPageTextDescriptions } = contactContent;
  if (
    contactPageTextHeader === undefined ||
    contactPageTextDescriptions === undefined
  ) {
    return null;
  }
  return (
    <>
      <div className="contact-container wrapper">
        <SideNav title="CONTACT" containerStyle={{ top: "0" }} isTop={true} />
        <div className="contact">
          <div className="contact-container-header">
            <h2>{contactPageTextHeader}</h2>
            {contactPageTextDescriptions.map((text) => (
              <p>{text}</p>
            ))}
          </div>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                className="custom-contact-form"
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
          {/* <ContactSection /> */}
        </div>
      </div>
      {/* <Footer hasLine /> */}
      <FooterConsultation />
      <Footer2022 />
    </>
  );
};

export default Contact;
