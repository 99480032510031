import React from "react";
import "./styles.scss";

export default (props) => (
  <div
    className={props.parentStyle.classNames}
    data-aos="fade-up"
    data-aos-duration="1000"
  >
    <iframe
      title={props.videoSrc || props.videoUrl}
      src={props.videoSrc || props.videoUrl}
      style={props.iframe.style}
      width={props.iframe.width}
      height={props.iframe.height}
      className={props.iframe.classNames}
      allowFullScreen={true}
      frameBorder="0"
    />
  </div>
);
