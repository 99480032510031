import pt from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Flex } from "@rebass/grid";

import { Small } from "../../common/Small";

import {
  Button,
  ImageBox,
  ResultContainer,
  Text,
  TextContainer,
} from "./components";

const Results = ({ date, title, blogPostNumber, imageUrl, text }) => {
  const history = useHistory();
  // const { pathname } = useLocation()

  const _navigateToBlog = (blogNumber) => {
    history.push(`/blogs/${blogNumber}`);
  };

  return (
    <ResultContainer
      width={["100%", "100%", "50%", "50%", "33%"]}
      mb={[80, 80, 80, 80, 80, 80, 200]}
      px={[25, 25, 25, 12.5, 12.5, 12.5, 25]}
      onClick={() => {
        _navigateToBlog(blogPostNumber);
      }}
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <Box>
        <Small
          style={{
            textTransform: "uppercase",
            fontSize: "0.8em",
            letterSpacing: "1px",
          }}
        >
          {date}
        </Small>
      </Box>
      <Box width="100%">
        <h2
          style={{
            fontSize: "1.5em",
            lineHeight: "1.5em",
            marginLeft: "0",
            marginRight: "0",
          }}
        >
          {title}
        </h2>
      </Box>
      <Box width={"100%"}>
        <ImageBox width={"100%"} height={[220, 275]} imageUrl={imageUrl} />
      </Box>
      <TextContainer my={[20]} width={"100%"}>
        <Text>{text}</Text>
      </TextContainer>
      <Flex>
        <Box>
          <Button>READ</Button>
        </Box>
      </Flex>
    </ResultContainer>
  );
};

Results.propTypes = {
  date: pt.string.isRequired,
  blogPostNumber: pt.number.isRequired,
  title: pt.string.isRequired,
  imageUrl: pt.string.isRequired,
  text: pt.string.isRequired,
};

export default Results;
