import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Box } from '@rebass/grid'

import colors from '../../theme/colors'

import { saveBlogs } from '../../actions/blogs'
import { getAllBlogs } from '../../selectors/blogs'
import { getBlogs } from '../../services/getContentfulBlog'

import ContactSection from '../common/ContactSection'
import Subscribe from '../common/Subscribe'

import View from './View'

const Journal = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const blogs = useSelector(getAllBlogs)
  const { blogId } = useParams()
  const massagedBlogId = parseInt(blogId, 10)

  useEffect(() => {
    if (!blogs.length) {
      const fetchData = async () => {
        const returnedBlogs = await getBlogs()
        dispatch(saveBlogs(returnedBlogs))
      }
      fetchData()
    }
  }, [])

  if (isNaN(massagedBlogId)) {
    history.push('/404')
  }

  if (!blogs.length) {
    return null
  }

  if (blogs.length < 2) {
    window.location.reload()
  }

  const isValidBlogNumber = blogs.length > 0
  const selectedBlog = blogs.filter((blog) => blog.fields.blogPostNumber === massagedBlogId)[0]

  const {
    title,
    subheader,
    content,
    blogPostNumber,
    bannerImage,
    author,
    dateAndTime
  } = isValidBlogNumber
      ? selectedBlog.fields
      : {
        content: {},
        blogPostNumber: '',
        bannerImage: {},
        author: {},
        dateAndTime: ''
      }


  if (!isValidBlogNumber) {
    return <div>That blog post doesn't exist</div>
  }

  const { url } = bannerImage.fields.file
  const authorFiles = {
    name: author.fields.name,
    url: author.fields.picture.fields.file.url,
    date: moment(dateAndTime).format('MMM Do YYYY')
  }

  return (
    <>
      <View
        title={title}
        subheader={subheader}
        blogPostNumber={blogPostNumber}
        content={content.content}
        bannerUrl={url}
        authorFiles={authorFiles}
      />
      <Box mt={[60, 60, 60, 132, 132, 132, 132, 178]}>
        <Subscribe backgroundColor={colors.themeGreen} color="#fff" />
      </Box>
      <Box>
        <ContactSection
          containerStyle={{
            backgroundColor: colors.themeGreen,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '52px 0'
          }}
          headerStyle={{
            color: colors.offWhite
          }}
        />
      </Box>
    </>
  )
}

export default Journal
