import styled from 'styled-components'
import { maxWidth, display } from 'styled-system'

import { Box } from '@rebass/grid'

export const Container = styled(Box)`
  ${maxWidth}
  margin: 0 auto;
  color: #fff;
  position: relative;
`

export const HeaderContainer = styled(Box)`
  text-align: center;
  ${maxWidth}
  margin: 0 auto;
`

export const ContentContainer = styled(Box)`
  ${display}
`

export const ContentHeader = styled.h4`
  font-weight: 500;
  font-size: 18px;
  margin: 0;
`

export const ContentDetails = styled.p`
  margin: 0;
  font-size: 18px;
`

export const MobileIcons = styled(Box)`
  ${display}
`
