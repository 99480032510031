// Rendered component will show a big component
import React, { Component } from "react";
import { Fade } from "react-reveal";

import "./styles/index.scss";

import SideMenu from "../../common/SideMenu";
import VideoEmbed from "../../common/videoEmbed";
// import LineVertical from "../../common/LineVertical";

class PortfolioIndividual extends Component {
  constructor(props) {
    super(props);

    const { individualType, nextPortfolio, prevPortfolio } = props;
    console.log(props)
    const videoStyle = {
      parentStyle: {
        classNames: "portfolio-video-container",
      },
      videoUrl: individualType.videoUrl,
      iframe: {
        style: {
          width: "100%",
        },
        width: "100%",
        height: "100%",
        classNames: "modal-demo-video",
      },
    };

    this.state = {
      show: false,
      showVideo: false,
      portfolioID: individualType.id,
      title: individualType.title,
      clientName: individualType.clientName,
      challenge: individualType.challenge,
      solution: individualType.solution,
      results: individualType.results,
      testimonial: individualType.testimonial,
      testimonialAuthor: individualType.testimonialAuthor,
      nextPortfolio: nextPortfolio.id,
      prevPortfolio: prevPortfolio.id,
      videoStyle,
    };

    // this.handleClick = this.handleClick.bind(this);
    this.navigatePortfolio = this.navigatePortfolio.bind(this);
  }

  // handleClick() {

  //     const newShowVideo =  this.state.showVideo ? false : true;

  //     this.setState({
  //         showVideo: newShowVideo
  //     });
  // }

  navigatePortfolio(e) {
    const { nextPortfolio, prevPortfolio } = this.props;

    const url =
      e.target.textContent.toLowerCase() === "prev"
        ? `/${prevPortfolio.url}`
        : `/${nextPortfolio.url}`;
    window.location.href = `/case-studies${url}`;
  }

  componentDidMount() {
    this.setState({
      show: true,
    });
  }

  componentDidUpdate(prevProps) {
    const { individualType } = this.props;

    if (prevProps.individualType.title !== individualType.title) {
      const videoStyle = {
        ...this.state.videoStyle,
        videoUrl: individualType.videoUrl,
      };

      this.setState({
        show: false,
        portfolioID: individualType.id,
        title: individualType.title,
        clientName: individualType.clientName,
        challenge: individualType.challenge,
        solution: individualType.solution,
        results: individualType.results,
        testimonial: individualType.testimonial,
        testimonialAuthor: individualType.testimonialAuthor,
        videoStyle,
      });

      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 250);
    }
  }

  render() {
    const {
      portfolioID,
      title,
      clientName,
      challenge,
      solution,
      results,
      testimonial,
      testimonialAuthor,
      videoStyle,
    } = this.state;

    const { nextPortfolio, prevPortfolio, individualType } = this.props;

    // Temp work to show/hide testimonial
    const testimonialStyle =
      typeof testimonial === "undefined"
        ? { display: "none" }
        : { display: "block" };

    // Scroll to top on load of component
    window.scrollTo(0, 0);

    return (
      <div>
        <Fade duration={this.state.show ? 1500 : 0} when={this.state.show}>
          <div
            className={`portfolio-description-screenshots portfolio-id-${portfolioID}`}
            style={{
              backgroundImage: `url(${individualType.image.fields.file.url})`,
            }}
          >
            <div className="portfolio-description-screenshots-text">
              <h2 data-aos="fade-up" data-aos-duration="1000">
                {title}
              </h2>
              <p
                className="portfolio-description-clientname"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {clientName}
              </p>
            </div>
          </div>
        </Fade>
        <div className="portfolio-single-container">
          <div className="portfolio-description-container wrapper">
            <SideMenu
              sideMenuClassName="prev"
              nextPortfolio={nextPortfolio}
              url={`/${prevPortfolio.url}`}
              title={prevPortfolio.title}
              handleClick={this.navigatePortfolio}
              sideMenuDirection="PREV"
            />
            <div className="portfolio-description">
              <div
                className="portfolio-description-text text-container"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 style={{ textAlign: "center" }}>Challenge</h2>
                <p className="intro">{challenge}</p>
              </div>
            </div>
            <VideoEmbed {...videoStyle} />
            <div
              className="portfolio-solutions text-container"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2 style={{ textAlign: "center" }}>Solution</h2>
              <p>{solution}</p>
            </div>
            <div
              className="portfolio-results text-container"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2 style={{ textAlign: "center", marginTop: "0" }}>Impact</h2>
              <p>{results}</p>
            </div>
            <SideMenu
              sideMenuClassName="next"
              nextPortfolio={nextPortfolio}
              url={`/${nextPortfolio.url}`}
              title={nextPortfolio.title}
              handleClick={this.navigatePortfolio}
              sideMenuDirection="NEXT"
            />
            {/* <LineVertical 
                            customStyle={{}}
                        /> */}
          </div>
          <div
            className="portfolio-banner portfolio-testimonial wrapper"
            style={testimonialStyle}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="text-container">
              <Fade bottom duration={1500}>
                <h2 className="wf-text-custom">"{testimonial}"</h2>
                <p
                  className="portfolio-testimonial-clientname"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  - {testimonialAuthor}
                </p>
              </Fade>
            </div>
          </div>
          <div className="portfolio-banner portfolio-next-container">
            <div
              className={`portfolio-banner portfolio-next portfolio-id portfolio-id-${nextPortfolio.id
                }`}
              style={{
                backgroundImage: `url(${nextPortfolio.image.fields.file.url})`,
              }}
              title={nextPortfolio.title}
              onClick={this.navigatePortfolio}
            >
              <div data-aos="fade-up" data-aos-duration="1000">
                <h2>{nextPortfolio.title}</h2>
                <h3>{nextPortfolio.clientName}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioIndividual;
