import pt from "prop-types";
import React from "react";
import { Fade } from "react-reveal";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import { Box, Flex } from "@rebass/grid";

import Banner from "../common/Banner";
import Grid from "../common/Grid";
import { Small } from "../common/Small";

import Footer from "../common/Footer";

import Facebook from "./Assets/Facebook.png";
import LinkedIn from "./Assets/LinkedIn.svg";
import Twitter from "./Assets/Twitter.svg";
import BlogContent from "./BlogContent";
import BlogNavigation from "./BlogNavigation";

import { ShareContainer } from "./components";

const View = ({
  title,
  subheader,
  blogPostNumber,
  content,
  bannerUrl,
  authorFiles,
}) => {
  return (
    <Box>
      <Banner
        imgUrl={bannerUrl}
        heroText={title}
        subText={{
          author: authorFiles.name,
          date: authorFiles.date,
        }}
      />
      <Grid maxWidth={[300, 375, 480, 640, 768, 800]} mt={80}>
        <Fade duration={1500}>
          <Box mb={[4]}>
            <h2
              style={{
                margin: 0,
              }}
            >
              {subheader}
            </h2>
          </Box>
        </Fade>
        <Box>
          <Fade duration={125}>
            {content.map((contentItem, index) => {
              return (
                <BlogContent
                  contentItem={contentItem}
                  key={`${contentItem.nodeType}-${index}`}
                />
              );
            })}
          </Fade>
        </Box>
        <Flex
          justifyContent={["flex-start", "flex-start", "space-between"]}
          flexDirection={["column", "column", "column", "row"]}
          alignItems={["center"]}
        >
          <Flex mb={[4, 0]}>
            <Flex
              justifyContent="center"
              flexDirection="center"
              alignItems="center"
              mr={[2]}
            >
              <img
                src={authorFiles.url}
                alt={authorFiles.name}
                style={{
                  borderRadius: "50%",
                  width: "42px",
                  height: "42px",
                }}
              />
            </Flex>
            <Flex
              justifyContent={["space-between", "flex-start", "center"]}
              flexDirection={["column", "row"]}
              alignItems={["flex-start", "center"]}
              width
            >
              <Small
                fontSize={["0.8em"]}
                letterSpacing={["1px"]}
                fontWeight={["bold"]}
                lineHeight={["1.3em", "1.3em", "0.8em", "0.8em", "0.9em"]}
                style={{ marginRight: "4px" }}
              >
                {`by ${authorFiles.name.toUpperCase()}`}
              </Small>
              <Small
                fontSize={["0.8em"]}
                letterSpacing={["1px"]}
                fontWeight={["bold"]}
                lineHeight={["1.3em", "1.3em", "0.8em", "0.8em", "0.9em"]}
              >
                {`on ${authorFiles.date.toUpperCase()}`}
              </Small>
            </Flex>
          </Flex>
          <Flex justifyContent={["center"]}>
            <Flex justifyContent={["center"]} alignItems="center" mr={[2]}>
              <Small
                style={{ transform: "translateY(2px)" }}
                fontSize={["0.8em"]}
                letterSpacing={["1px"]}
                fontWeight={["bold"]}
                lineHeight={["1.1em", "1.1em", "0.8em", "0.8em", "0.9em"]}
              >
                SHARE THIS
              </Small>
            </Flex>
            <ShareContainer>
              <Flex
                justifyContent="center"
                flexDirection="center"
                alignItems="center"
                mr={[1]}
              >
                <TwitterShareButton
                  url={`www.wakefield.agency/blogs/${blogPostNumber}`}
                  style={{
                    padding: "0 !important",
                  }}
                >
                  <img
                    src={Twitter}
                    alt="Twitter"
                    height="24px"
                    width="24px"
                    style={{ display: "block" }}
                  />
                </TwitterShareButton>
              </Flex>
              <Flex
                justifyContent="center"
                flexDirection="center"
                alignItems="center"
                mr={[1]}
              >
                <FacebookShareButton
                  url={`www.wakefield.agency/blogs/${blogPostNumber}`}
                  style={{
                    padding: "0 !important",
                  }}
                >
                  <img
                    src={Facebook}
                    alt="facebook"
                    height="24px"
                    width="24px"
                    style={{ display: "block" }}
                  />
                </FacebookShareButton>
              </Flex>
              <Flex
                justifyContent="center"
                flexDirection="center"
                alignItems="center"
              >
                <LinkedinShareButton
                  url={`www.wakefield.agency/blogs/${blogPostNumber}`}
                  style={{
                    padding: "0 !important",
                  }}
                >
                  <img
                    src={LinkedIn}
                    alt="LinkedIn"
                    height="24px"
                    width="24px"
                    style={{ display: "block" }}
                  />
                </LinkedinShareButton>
              </Flex>
            </ShareContainer>
          </Flex>
        </Flex>
        <Footer
          containerStyle={{
            height: "auto",
            margin: "100px 0 80px 0",
          }}
          textContent="Have a story to tell? We can help you make an impact"
          ctaText="Connect with us"
        />
        <BlogNavigation blogPostNumber={blogPostNumber} />
      </Grid>
    </Box>
  );
};

View.propTypes = {
  title: pt.string.isRequired,
  subheader: pt.string,
  blogPostNumber: pt.number.isRequired,
  content: pt.array.isRequired,
  bannerUrl: pt.string.isRequired,
  authorFiles: pt.object.isRequired,
};

View.defaultProps = {
  subheader: "",
};

export default View;
