import moment from "moment";
import pt from "prop-types";
import React from "react";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

import { Box } from "@rebass/grid";

import LineVertical from "../common/LineVertical";
import Filter from "./components/Filter";

const View = ({
  blogs,
  value,
  handleChange,
  handleClick,
  handleSearch,
  handleBlogs,
  filterValue,
  setFilterValue,
}) => {
  return (
    <Box>
      <div className="blog-header wrapper">
        <LineVertical />
        <div className="blog-header-content">
          <h2 data-aos="fade-up" data-aos-duration="1000">
            Storytelling insights to <br />
            grow your impact
          </h2>
        </div>
        <div
          className="header-content-text"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="400"
        >
          <span>Wakefield Blogs</span>
        </div>
      </div>
      <Box mx={[30, 30, 30, 90]}>
        <Filter
          handleChange={handleChange}
          handleSearch={handleSearch}
          value={value}
          filterValue={filterValue}
          setFilterValue={setFilterValue}
        />
      </Box>
      <div className="blog-list grid portfolios-all">
        {blogs.map((blog, i) => {
          const { blogPostNumber, dateAndTime } = blog.fields;
          const { url } = blog.fields.bannerImage.fields.file;

          const date = moment(dateAndTime).format("MMM D, YYYY");
          const category = blog.fields.category;
          return (
            <Link
              to={`/blogs/${blogPostNumber}`}
              onClick={(e) => {
                handleClick(e, `/blogs/${blogPostNumber}`);
              }}
              key={i}
              className={"blog-link element-item " + category}
            >
              <Fade duration={1500}>
                <div
                  className="blog-thumbnail portfolio-id portfolio-img"
                  style={{
                    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${url}) no-repeat center`,
                  }}
                >
                  <div
                    className="portfolio-info"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-offset="-100"
                  >
                    <h3 className="blog-title">{blog.fields.title}</h3>
                    <p className="blog-date">{date}</p>
                  </div>
                </div>
              </Fade>
            </Link>
          );
        })}
      </div>
      {filterValue === "All" ? (
        <div className="buttonWrap">
          <button className="wf-contact-submit" onClick={() => handleBlogs()}>
            Show more
          </button>
        </div>
      ) : null}
    </Box>
  );
};

View.propTypes = {
  blogs: pt.array.isRequired,
  searchValue: pt.func.isRequired,
  handleChange: pt.func.isRequired,
  handleClick: pt.func.isRequired,
  handleSearch: pt.func.isRequired,
};

export default View;
