import React from "react";
import { useSelector } from "react-redux";
import { getFooterContent, getHomepageContent } from "../../../selectors/content";
import Button from "../Button";
import LineVertical from "../LineVertical";
import "./footer-consultation.scss";

export const FooterConsultation = () => {
  const homeContent = useSelector(getHomepageContent)
  const footerContent = useSelector(getFooterContent);
  const { generalFooterTextLink } = footerContent;
  return (
    <div className="about-footer wrapper">
      <LineVertical />
      <div className="about-footer-content">
        <h2 data-aos="fade-up" data-aos-duration="1000">
          {generalFooterTextLink}
        </h2>
        <Button
          text={homeContent.homepageHeroButtonText}
          isNewWindow
          href="https://wakefield.agency/about"
          style={{ justifyContent: "center" }}
        />
        <div style={{ height: "40px" }} />
      </div>
    </div>
  );
};
