import React from "react";
import pt from "prop-types";
import { Box } from "@rebass/grid";

import ThemeButton from "./Button";

const Button = ({
  text,
  handleClick = () => null,
  isNewWindow = false,
  href = "/",
  style = {},
}) => {
  const boxStyle = {
    display: "flex",
    ...style,
  };
  return (
    <Box style={boxStyle} onClick={handleClick}>
      <ThemeButton
        type="button"
        target={isNewWindow ? "_blank" : undefined}
        href={href}
        rel={isNewWindow ? "noopener noreferrer" : undefined}
      >
        {text}
      </ThemeButton>
    </Box>
  );
};

Button.proptypes = {
  text: pt.string.isRequired,
  handleClick: pt.func.isRequired,
};

export default Button;
