import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PanelList } from "../common/Panels";

import { getAllCaseStudies } from "../../actions/case-studies";
import { getAllCaseStudies as getAlCaseStudiesSelector } from "../../selectors/case-studies";

// import portfolios from "./list-of-portfolios";
import { useCreateBlockyNav } from "../../hooks/use-create-blocky-nav";
import { getHomepageContent } from "../../selectors/content";
import { getAllCaseStudes } from "../../services/getContent";
import { Footer2022 } from "../common/Footer2022";
import { FooterConsultation } from "../common/footer-consultation";
import "./styled/index.scss";
import { StyledPanel } from "./styled/styled";

// const filterOptions = [
//   // "GENERAL",
//   // "MESSAGING",
//   // "WEBSITE SALES FUNNEL",
//   // "VIDEO & PHOTO",
//   // "NURTURE CAMPAIGNS",
// ];

const List = (props) => {
  const dispatch = useDispatch();
  const caseStudies = useSelector(getAlCaseStudiesSelector);

  useEffect(() => {
    if (!caseStudies.length) {
      const fetchData = async () => {
        const data = await getAllCaseStudes();
        dispatch(getAllCaseStudies(data));
      };

      fetchData();
    }
  }, []);

  if (!caseStudies.length) {
    return null;
  }

  return (
    <div className="blog-list grid portfolios-all">
      {caseStudies.map((portfolio) => {
        const {
          title,
          image,
          // category,
          clientName,
          url,
          filterOption,
        } = portfolio;
        // console.log(portfolio)
        const imgSrc = image.fields.file.url;
        const pathname = `/case-studies/${url}`;

        // if (
        //   props.filterOption !== "GENERAL" &&
        //   props.filterOption !== filterOption
        // ) {
        //   return <></>;
        // }

        return (
          <Link
            to={pathname}
            key={pathname}
            className={"blog-link element-item"}
          >
            <StyledPanel
              className="blog-thumbnail portfolio-id portfolio-img"
              imgSrc={imgSrc}
            >
              <div
                className="portfolio-info"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-offset="-100"
              >
                <h3 className="blog-title">{title}</h3>
                <p className="blog-date">{clientName}</p>
              </div>
            </StyledPanel>
          </Link>
        );
      })}
    </div>
  );
};

export const CaseStudies = () => {
  const generalContent = useSelector(getHomepageContent)
  useCreateBlockyNav();
  return (
    <>
      <PanelList
        headerText={generalContent.caseStudiesHeroText}
        List={List}
        filterOptions={[]}
      />
      <FooterConsultation />
      <Footer2022 />
    </>
  );
};
