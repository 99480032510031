import React from 'react'
import pt from 'prop-types'
import { Fade } from 'react-reveal'

import { BannerContainer } from './components'

const Banner = ({
  imgUrl,
  heroText,
  subText
}) => {
  const isSubTextString = typeof subText === 'string' ? true : false
  return (
    <Fade duration={1500}>
      <BannerContainer imgUrl={imgUrl}>
        <BannerContainer.TextContainer
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-offset="-100"
        >
          <BannerContainer.HeroText>
            {heroText}
          </BannerContainer.HeroText>
          {
            isSubTextString
              ? (
                <BannerContainer.SubText>
                  <span>{subText}</span>
                </BannerContainer.SubText>
              )
              : (
                <div style={{ textAlign: 'center' }}>
                  <BannerContainer.SubText>
                    by{' '}
                    <BannerContainer.SubText.Underline>
                      {subText.author} 
                    </BannerContainer.SubText.Underline> 
                    {' '}on{' '} 
                    <BannerContainer.SubText.Underline>
                    {subText.date}
                    </BannerContainer.SubText.Underline>
                  </BannerContainer.SubText>
                </div>
              )
          }
        </BannerContainer.TextContainer>
      </BannerContainer>
    </Fade>
  )
}

Banner.propTypes = {
  imgUrl: pt.string.isRequired,
  heroText: pt.string,
  subText: pt.oneOfType([
    pt.string,
    pt.object
  ])
}

Banner.defaultProps = {
  heroText: '',
  subText: {
    author: 'Nouver Cheung',
    date: 'Oct 20, 2019'
  }
}

export default Banner
