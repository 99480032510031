import React from 'react';

import './styles.scss';

export default props => {
    return (
        <div className="sns-container" style={props.containerStyle}>
            {
                props.showEmail
                    ? 
                    <div className="sns-main">
                        <span>EMAIL</span>
                        <div>
                            <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=hello@wakefieldproductions.com">hello@wakefieldproductions.com</a>
                        </div>
                    </div>
                    : ``
            }
            <div className="sns-main sns-follow">
                <span class="follow" style={props.headerStyle}>Follow Us</span>
                <div>
                    <span style={{ marginRight: "40px" }}>
                        <a href="https://www.instagram.com/wakefieldagency/">Instagram</a>
                    </span>
                    <span style={{ marginRight: "40px" }}>
                        <a href="http://facebook.com/wakefieldagency">Facebook</a>
                    </span>
                    <span>
                        <a href="https://www.linkedin.com/company/wakefieldagency">LinkedIn</a>
                    </span>
                </div>
            </div>
        </div>
    );
}
