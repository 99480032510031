import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { createBlockyNav as createNav } from "../actions";
import { initialState as initialNavState } from "../reducers";

export const useCreateBlockyNav = () => {
  const dispatch = useDispatch();

  // TODO: reuseability in creating blocky nav
  useEffect(() => {
    const headerWrapperStyle = {
      height: "60px",
    };

    const classNameNavBar = "header-container header-container-solid";
    const classNameBurgerIcon = "header-menu-icon header-menu-icon-black";
    const shouldChangeNav = false;

    dispatch(
      createNav({
        shouldChangeNav,
        headerWrapperStyle,
        classNameNavBar,
        classNameBurgerIcon,
      })
    );

    return () => {
      dispatch(createNav(initialNavState));
    };
  }, []);
};
