import styled from "styled-components";

import colors from "../../../theme/colors";

// Padding values is because purchased font family doesnt center vertically
// Along with :after arrow
export default styled.a`
  background-color: ${() => colors.orange};
  color: #fff !important;
  font-size: 16px;
  font-family: Sailec-bold;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 18px 12px 13px 16px;
  box-shadow: none;
  border: none;
  &::after {
    content: "→";
    margin-left: 3px;
    transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover::after {
    margin-left: 6px;
  }
`;
