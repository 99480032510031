import React, { Component } from 'react';
// import { createBrowserHistory } from 'history';
import NavMenu from '../NavMenu';
import Nav from '../Nav';
import { connect } from 'react-redux';

import './styles.scss';

class Header extends Component {
    constructor(props) {
        super(props);
        
        const classNameNavBar = 'header-container';
        const classNameBurgerIcon = 'header-menu-icon';

        this.state = {
            showNav: false,
            showNavHoverImage: '',
            classNameNavBar,
            classNameBurgerIcon,
            headerWrapperStyle: this.props.headerWrapperStyle,
            shouldChangeNav: true
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleHoverOfMenu = this.handleHoverOfMenu.bind(this);
    }

    // Determine whether to show nav or nav menu
    handleClick(e) {    
        if (e.currentTarget.className.indexOf('nav-menu-links') > -1) {
            const status = this.state.showNav ? false : true;
            this.setState({
                showNav: status
            });
        } else if (e.currentTarget.className.indexOf('nav-menu-close') > -1) {
            document.getElementsByClassName('nav-menu')[0].classList.add('hide');

            setTimeout(() => {
                this.setState({
                    showNav: false
                });    
            }, 300);
            
        } else {
            const status = this.state.showNav ? false : true;
        
            this.setState({
                showNav: status
            });
        }
        
    }

    handleHoverOfMenu(e) {
        
        const page = e.target.pathname;

        this.setState({
            showNavHoverImage: page
        });

    }

    componentDidUpdate(prevProps) {
        
        // To update classes for appropriate pages
        if (prevProps.shouldChangeNav !== this.props.shouldChangeNav) {
            this.setState({
                shouldChangeNav: this.props.shouldChangeNav,
                headerWrapperStyle: this.props.headerWrapperStyle,
                classNameNavBar: this.props.classNameNavBar,
                classNameBurgerIcon: this.props.classNameBurgerIcon
            });
        }
    }

    render() {
        const { 
            showNav, 
            showNavHoverImage,
            shouldChangeNav,
            classNameNavBar, 
            headerWrapperStyle,  
            classNameBurgerIcon, 
        } = this.state;
        
        // Scroll action for nav bar
        if (!showNav && shouldChangeNav) {
            
            window.onscroll = () => {

                if (window.pageYOffset > 90) {
                    const nameScrolledNav = 'header-container header-container-solid';
                    const classNameBurgerIcon = 'header-menu-icon header-menu-icon-black';

                    this.setState({
                        classNameNavBar: nameScrolledNav,
                        classNameBurgerIcon
                    });
                } else {
                    const classNameBurgerIcon = 'header-menu-icon';
                    this.setState({
                        classNameNavBar: 'header-container',
                        classNameBurgerIcon
                    });
                }
                
            }
        }  else {
            window.onscroll = null;
        }
        
        // Determine which component to render
        const HeaderComponent = 
            showNav 
                ? <NavMenu 
                    showNavHoverImage={showNavHoverImage}
                    onClick={this.handleClick} 
                    handleHoverOfMenu={this.handleHoverOfMenu}
                    /> 
                : <Nav 
                    onClick={this.handleClick} 
                    navClassName={classNameNavBar} 
                    classNameBurgerIcon={classNameBurgerIcon} 
                />

        return (
            <div className="header-wrapper" style={headerWrapperStyle}>
                {HeaderComponent}
            </div>);
    }
}

const mapStateToProps = state => {
    return {
        shouldChangeNav: state.main.shouldChangeNav,
        headerWrapperStyle: state.main.headerWrapperStyle,
        classNameNavBar: state.main.classNameNavBar,
        classNameBurgerIcon: state.main.classNameBurgerIcon,
        navigateToPortfolio: state.main.navigateToPortfolio
    }
}

export default connect(mapStateToProps)(Header);