import React from "react";
import { useCreateBlockyNav } from "../../../hooks/use-create-blocky-nav";
import pt from "prop-types";
import { Results } from "./components/results";
import { Query } from "./components/query";

export const PanelSearch = ({ searchResults, ctaText, query }) => {
  useCreateBlockyNav();
  return (
    <>
      <Query query={query} />
      <Results searchResults={searchResults} ctaText={ctaText} />
    </>
  );
};

PanelSearch.PropTypes = {
  searchResults: pt.array.isRequired,
  ctaText: pt.string.isRequired,
  query: pt.string.isRequired,
};
