import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import ReactBreakpoints from 'react-breakpoints'

import breakpoints from './constants/breakpoints'

ReactDOM.render(
  <ReactBreakpoints 
    breakpoints={breakpoints}
    // debounceResize={true}
    // debounceDelay={250}
  >
    <App />
  </ReactBreakpoints>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
