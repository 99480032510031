import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import mainReducer from "../reducers";
import blogReducer from "../reducers/blogs";
import contentReducer from "../reducers/content";
import bioReducer from "../reducers/teamBios";
import caseStudiesReducer from "../reducers/case-studies";

const rootReducer = combineReducers({
  main: mainReducer,
  blog: blogReducer,
  content: contentReducer,
  bio: bioReducer,
  caseStudies: caseStudiesReducer,
});

const middleware = [thunk];

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize..
      })
    : compose;

// other store enhancers if any
const enhancer = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(
  rootReducer,
  enhancer
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
