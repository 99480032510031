import styled from "styled-components";
import { width } from "styled-system";

import { Flex, Box } from "@rebass/grid";

export const ResultContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  ${width}
`;

export const ImageBox = styled(Box)`
  background-image: ${({ imageUrl }) => `url("${imageUrl}")`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const TextContainer = styled(Box)`
  height: 68px;
  overflow-y: hidden;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 0.9em;
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.themeGreen};
  color: #fff;
  outline: none;
  padding: 8px 34px;
  border: none;
  cursor: pointer;
  font-size: 0.9em;
  letter-spacing: normal;
  font-weight: 500;
  &::after {
    content: "→";
    margin-left: 3px;
    transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  &:hover::after {
    margin-left: 6px;
  }
`;
