import { SAVE_BIOS } from "../constants";

const initialState = {
  bio: {},
};

const bioReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_BIOS:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
};

export default bioReducer;
