import React from 'react'
import '../styles/filter.scss'
const Filter = ({
  handleChange,
  handleSearch,
  value,
  filterValue,
  setFilterValue
}) => {
  return (
    <div className='filterBar'>
      <div className='filter'>
        <button
          style={{ outline: 'none', cursor: 'pointer' }}
          className={filterValue === 'All' ? 'active' : ''}
          onClick={() => setFilterValue('All')}
        >
          All
        </button>
        <button
          style={{ outline: 'none', cursor: 'pointer' }}
          className={filterValue === 'Storytelling' ? 'active' : ''}
          onClick={() => setFilterValue('Storytelling')}
        >
          Storytelling
        </button>
        <button
          style={{ outline: 'none', cursor: 'pointer' }}
          className={filterValue === 'Projects' ? 'active' : ''}
          onClick={() => setFilterValue('Projects')}
        >
          Projects
        </button>
      </div>
      <form
        id='searchForm'
        method='get'
        className='search'
        onSubmit={e => handleSearch(e)}
      >
        <input
          className="wf-filter-input"
          type='text'
          onChange={e => handleChange(e)}
          value={value}
        />
        <input type='submit' value='Submit' style={{ display: 'none' }} />
      </form>
    </div>
  )
}

export default Filter
