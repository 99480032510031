export default {
  spaceID: "a826mlmnqk6u",
  contentAPI: "VrTbZh_fquM24pGoCpsjFXqo74R8MPiGL516ZlZIY-E",
  previewAPI: "FqO3DLIA27dTC9lXnl2NG2-Z5SqlruVPkxwLgIuN_Vg",
  content: {
    homepageContentId: "6ZUMxdMv9ZJYPl43IXfFee",
  },
  MAILCHIMP_SNIPPET:
    "https://wakefieldproductions.us7.list-manage.com/subscribe/post?u=aa566ecee5f06387e4184b7e8&id=2f16f4c279",
};
